import { useEffect, useState, useContext, useCallback } from "react";
import Logic from "../results-tracker/logic";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import { DataFetcher } from "../../utils/dataFetcher";
import throwMessage from "../../utils/throwMessage";
import markets from "../../lib/markets";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment";
import dayjs from "dayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import CustomMultiTextField from "../../components/textfields/multiRow_standard";
import CustomDatePicker from "../../components/dateTimePickers/datePicker";
import { DataGrid } from "@mui/x-data-grid";
import columnsReflections from "../../lib/columnsReflections";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useLongPress } from "use-long-press";
import Tooltip from "@mui/material/Tooltip";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import Switch from "@mui/material/Switch";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import JoinRightOutlinedIcon from "@mui/icons-material/JoinRightOutlined";
import useWindowDimensions from "../../utils/width";

const SingleReflection = ({
  setSingleReflectionView,
  handleScroll,
  setSingleJournalView,
  setOpenJournal,
  setSingleForecastView,
  handleCloseNewReflection,
}) => {
  const { width } = useWindowDimensions();
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const [date, setDate] = useState(dayjs(new Date()));
  const [id, setId] = useState("");
  const [typeOfReview, setTypeOfReview] = useState("");
  const [question_1, setQuestion_1] = useState("");
  const [question_2, setQuestion_2] = useState("");
  const [question_3, setQuestion_3] = useState("");
  const [question_4, setQuestion_4] = useState("");
  const [question_5, setQuestion_5] = useState("");
  const [question_6, setQuestion_6] = useState("");
  const [question_7, setQuestion_7] = useState("");
  const [question_8, setQuestion_8] = useState("");
  const [q1, setQ1] = useState(undefined);
  const [q2, setQ2] = useState(undefined);
  const [q3, setQ3] = useState(undefined);
  const [q4, setQ4] = useState(undefined);
  const [weekly, setWeekly] = useState(undefined);
  const [monthly, setMonthly] = useState(undefined);
  const [Annual, setAnnual] = useState(undefined);
  const [hideTypeOf, setHideTypeOf] = useState(true);
  const [changeDate, setChangeDate] = useState(false);
  const [trades, setTrades] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [finding, setFinidng] = useState(true);
  const [missedTrades, setMissedTrades] = useState(false);

  const [isHoveringNext, setIsHoveringNext] = useState(false);
  const [isHoveringBack, setIsHoveringBack] = useState(false);

  const [returnPercentOptions, setReturnPercentOptions] = useState(0);
  const [returnPercentPC, setReturnPercentPC] = useState(0);
  const [returnPercentIC, setReturnPercentIC] = useState(0);
  const [returnDollarPersonal, setReturnDollarPersonal] = useState(0);
  const [returnDollarInvestor, setReturnDollarInvestor] = useState(0);
  const [returnDollarOptions, setReturnDollarOptions] = useState(0);

  const [winsPC, setWinsPC] = useState(0);
  const [lossPC, setLossPC] = useState(0);
  const [inProgressPC, setInProgressPC] = useState(0);
  const [winsIC, setWinsIC] = useState(0);
  const [lossIC, setLossIC] = useState(0);
  const [inProgressIC, setInProgressIC] = useState(0);
  const [bePC, setBePC] = useState(0);
  const [beIC, setBeIC] = useState(0);

  const [winsOptions, setWinsOptions] = useState(0);
  const [lossOptions, setLossOptions] = useState(0);
  const [inProgressOptions, setInProgressOptions] = useState(0);
  const [beOptions, setBeOptions] = useState(0);

  const mode = localStorage.getItem("equity-mode");

  const { calculateFields } = Logic();

  const handleSave = async () => {
    if (typeOfReview) {
      const schema = {
        typeOfReview: typeOfReview,
        date: date,
        question_1: question_1,
        question_2: question_2,
        question_3: question_3,
        question_4: question_4,
        question_5: question_5,
        question_6: question_6,
        question_7: question_7,
        question_8: question_8,
        _id: id,
      };
      if (!formSubmitted);
      setFormSubmitted(true);
      const result = await DataFetcher("edit-reflection", schema);
      if (result) {
        const filteredArray = auth.reflections.filter((x) => x._id !== id);
        filteredArray.push(result);
        auth.setReflections(filteredArray);
        throwMessage("success", "Success");
        setFormSubmitted(false);
      } else {
        setFormSubmitted(false);
        throwMessage("error", "Something went wrong");
      }
    } else {
      throwMessage("warning", "Missing Fields");
    }
  };
  const checkStopLossMutiplyer = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { multiplier: pairObject?.multiplier };

    return schema;
  };
  const checkTypeOfPair = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { pipDistance: pairObject?.pipDistance };

    return schema;
  };
  const calculateOutcomeOfSlippage = (
    closePrice,
    openPrice,
    direction,
    pair
  ) => {
    const typeofPair = checkTypeOfPair(pair);

    if (isNaN(closePrice) || closePrice === "") {
      return "In Progress";
    } else {
      if (closePrice === openPrice) {
        return "Breakeven";
      }

      if (direction === "Long") {
        if (closePrice < openPrice) {
          const amountOfPipsLostBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice > openPrice) {
          const amountOfPipsWonBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      } else if (direction === "Short") {
        if (closePrice > openPrice) {
          const amountOfPipsLostBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice < openPrice) {
          const amountOfPipsWonBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      }
    }
  };
  const workOutWinLoseInPips = (
    outcome,
    direction,
    closePrice,
    openPrice,
    pair
  ) => {
    let pipsWonBy;
    let pipsLostBy;

    const typeofPair = checkTypeOfPair(pair);
    if (outcome === "Breakeven" || outcome === "In Progress") {
      pipsWonBy = 0;
      pipsLostBy = 0;
    }
    // handle longs
    if (direction === "Long" && outcome === "Win") {
      const amountOfPipsWonBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Long" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }
    // handle Shorts
    if (direction === "Short" && outcome === "Win") {
      const amountOfPipsWonBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Short" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }

    return { pipsLostBy: pipsLostBy, pipsWonBy: pipsWonBy };
  };
  const calculatePercentageReturn = (
    outcome,
    direction,
    pair,
    pipsWonBy,
    pipsLostBy,
    openPrice,
    risk,
    stopLossPips
  ) => {
    let percent = 0;
    let stop;
    let ESD;
    let DWP = pipsWonBy;
    let DLP = pipsLostBy;

    const multiplier = checkStopLossMutiplyer(pair).multiplier;
    const stopLoss = parseFloat(stopLossPips) / multiplier;
    const pipDistance = checkTypeOfPair(pair).pipDistance;

    if (outcome === "Breakeven" || outcome === "In Progress") {
      percent = 0;
    }

    if (direction === "Long" && outcome === "Win") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Long" && outcome === "Loss") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Loss") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Win") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }

    return percent;
  };
  const runCalculations = async (_trades) => {
    let trades = _trades;
    let virtualArray = [];

    let totalOutcomesPC = [];
    let totalOutcomesIC = [];
    let totalOutcomesOptions = [];

    let returnDollarTotals_Investor_summed;
    let returnDollarTotals_Personal_summed;
    let returnDollarTotals_Options_summed;

    let returnPercentTotalsIC = [];
    let returnDollarTotalsInvestor = [];

    let returnPercentTotalsPC = [];
    let returnDollarTotalsPersonal = [];

    let returnPercentTotalsOptions = [];
    let returnDollarTotalsOptions = [];
    let balanceOptions;
    let rollingReturnsOptions = 0;
    let profitLossDollarInvestor;
    let profitLossPercentageIC;
    let profitLossDollarPersonal;
    let profitLossPercentagePC;
    let allFeesIC = [];
    let allFeesPC = [];

    if (mode === "Personal Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType === "Personal Capital"
      );
    } else if (mode === "Investor Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType === "Investor Capital"
      );
    } else if (mode === "Options") {
      trades = trades.filter((trades) => trades?.equityType === "Options");
    } else if (mode === "Personal & Investor Capital") {
      trades = trades.filter((trades) => trades?.equityType !== "Options");
    }

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const capital = trade?.equityType;

      if (capital === "Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        allFeesIC.push(parseFloat(trade.feeIC));

        if (outcomeOfSlippageIC === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcomeOfSlippageIC === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (
          returnPercentIC === "Breakeven" ||
          returnPercentIC === "In Progress" ||
          outcomeOfSlippageIC === "Breakeven" ||
          outcomeOfSlippageIC === "In Progress"
        ) {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        returnPercentTotalsIC.push(profitLossPercentageIC);
        returnDollarTotalsInvestor.push(profitLossDollarInvestor);
        totalOutcomesIC.push(outcomeOfSlippageIC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          // riskValue: parseFloat(riskValue.toFixed(2)),
          // valuePerPip: parseFloat(valuePerPip.toFixed(2)),
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: 0,
          profitLossPercentagePC: 0,
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          // rr: riskToRewardDecimal.toFixed(2),
          outcomePC: "",
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: 0,
          closePricePC: 0,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: 0,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
          returnTotalsPersonal: 0,
          returnTotalsInvestor: returnDollarTotals_Investor_summed?.toFixed(2),
          created: trade.created,
          equityType: trade?.equityType,
        };
        virtualArray.push(schema);
      } else if (capital === "Personal Capital") {
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippagePC,
          // outcome,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );
        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );
        allFeesPC.push(parseFloat(trade.feePC));
        if (outcomeOfSlippagePC === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcomeOfSlippagePC === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (
          outcomeOfSlippagePC === "Breakeven" ||
          outcomeOfSlippagePC === "In Progress" ||
          returnPercentPC === "Breakeven" ||
          returnPercentPC === "In Progress"
        ) {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        returnPercentTotalsPC.push(profitLossPercentagePC);
        returnDollarTotalsPersonal.push(profitLossDollarPersonal);

        totalOutcomesPC.push(outcomeOfSlippagePC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: 0,
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: "",
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed?.toFixed(2),
          returnTotalsInvestor: 0,
          created: trade.created,
          equityType: trade?.equityType,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
        };
        virtualArray.push(schema);
      } else if (capital === "Personal & Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );

        allFeesIC.push(parseFloat(trade.feeIC));
        allFeesPC.push(parseFloat(trade.feePC));

        if (outcomeOfSlippageIC === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcomeOfSlippageIC === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (
          outcomeOfSlippageIC === "Breakeven" ||
          outcomeOfSlippageIC === "In Progress"
        ) {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        if (outcomeOfSlippagePC === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcomeOfSlippagePC === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (
          outcomeOfSlippagePC === "Breakeven" ||
          outcomeOfSlippagePC === "In Progress"
        ) {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        returnPercentTotalsIC.push(profitLossPercentageIC);
        returnDollarTotalsInvestor.push(profitLossDollarInvestor);

        returnPercentTotalsPC.push(profitLossPercentagePC);
        returnDollarTotalsPersonal.push(profitLossDollarPersonal);

        totalOutcomesPC.push(outcomeOfSlippagePC);
        totalOutcomesIC.push(outcomeOfSlippageIC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed?.toFixed(2),
          returnTotalsInvestor: returnDollarTotals_Investor_summed?.toFixed(2),
          created: trade.created,
          equityType: trade?.equityType,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
        };

        virtualArray.push(schema);
      } else if (capital === "Options") {
        const dollarReturn = Number(trade.profitLossDollarOptions);
        const premium = Number(trade.premium);

        let returnPercent;
        let riskPercent;

        const t = await calculateFields(auth.trades, auth.backtestingMode);
        returnPercent = Number(
          t.find((t) => t.id === trade.id)?.profitLossPercentageOptions
        );
        riskPercent = Number(t.find((t) => t.id === trade.id)?.riskOptions);

        balanceOptions = balanceOptions - Number(trade.premium) + dollarReturn;

        totalOutcomesOptions.push(
          dollarReturn > premium
            ? "Win"
            : dollarReturn === premium
            ? "Breakeven"
            : dollarReturn < premium
            ? "Loss"
            : ""
        );

        returnPercentTotalsOptions.push(returnPercent);
        returnDollarTotalsOptions.push(dollarReturn - premium);

        const newTrade_virtual = {
          outcomeOptions:
            dollarReturn > premium
              ? "Win"
              : dollarReturn === premium
              ? "Breakeven"
              : dollarReturn < premium
              ? "Loss"
              : "",
          rollingBalanceOptions: balanceOptions.toFixed(2),
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossPercentageOptions: returnPercent.toFixed(2),
          profitLossDollarOptions: trade.profitLossDollarOptions,
          returnTotalsOptions: rollingReturnsOptions.toFixed(2),
          pair: trade.pair,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          expiry: trade.expiry,
          // feeOptions: trade.feeOptions,
          riskOptions: riskPercent.toFixed(2),
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          pattern: trade.pattern,
          premium: trade.premium,
          optionType: trade.optionType,
          strike: trade.strike,
          quantity: trade.quantity,
          openPriceOptions: trade.openPriceOptions,
          closePriceOptions: trade.closePriceOptions,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          dxyHourlyBefore: trade.dxyHourlyBefore,
          dxyHourlyAfter: trade.dxyHourlyAfter,
          dxyDailyBefore: trade.dxyDailyBefore,
          dxyDailyAfter: trade.dxyDailyAfter,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: 0,
          returnTotalsInvestor: 0,
          created: trade.created,
          equityType: trade.equityType,
        };

        virtualArray.push(newTrade_virtual);
      }
    }

    const sumTotalProfitLossPercentagePC =
      returnPercentTotalsPC.length > 0
        ? returnPercentTotalsPC.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;
    const sumTotalProfitLossPercentageIC =
      returnPercentTotalsIC.length > 0
        ? returnPercentTotalsIC.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;
    const sumTotalProfitLossPercentageOptions =
      returnPercentTotalsOptions.length > 0
        ? returnPercentTotalsOptions.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    returnDollarTotals_Personal_summed =
      returnDollarTotalsPersonal.length > 0
        ? returnDollarTotalsPersonal.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    let allFees_summed_PC =
      allFeesPC.length > 0
        ? allFeesPC.reduce(function (a, b) {
            return a + b;
          })
        : 0;

    returnDollarTotals_Personal_summed =
      returnDollarTotals_Personal_summed + allFees_summed_PC;

    returnDollarTotals_Investor_summed =
      returnDollarTotalsInvestor.length > 0
        ? returnDollarTotalsInvestor.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    returnDollarTotals_Options_summed =
      returnDollarTotalsOptions.length > 0
        ? returnDollarTotalsOptions.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    let allFees_summed_IC =
      allFeesPC.length > 0
        ? allFeesPC.reduce(function (a, b) {
            return a + b;
          })
        : 0;

    returnDollarTotals_Investor_summed =
      returnDollarTotals_Investor_summed + allFees_summed_IC;

    const winsPC = totalOutcomesPC.filter((res) => res === "Win");
    const lossesPC = totalOutcomesPC.filter((res) => res === "Loss");
    const breakevensPC = totalOutcomesPC.filter((res) => res === "Breakeven");
    const inProgressPC = totalOutcomesPC.filter((res) => res === "In Progress");

    const winsIC = totalOutcomesIC.filter((res) => res === "Win");
    const lossesIC = totalOutcomesIC.filter((res) => res === "Loss");
    const breakevensIC = totalOutcomesIC.filter((res) => res === "Breakeven");
    const inProgressIC = totalOutcomesIC.filter((res) => res === "In Progress");

    const winsOptions = totalOutcomesOptions.filter((res) => res === "Win");
    const lossesOptions = totalOutcomesOptions.filter((res) => res === "Loss");
    const breakevensOptions = totalOutcomesOptions.filter(
      (res) => res === "Breakeven"
    );
    const inProgressOptions = totalOutcomesOptions.filter(
      (res) => res === "In Progress"
    );

    setInProgressPC(inProgressPC.length);
    setBePC(breakevensPC.length);
    setLossPC(lossesPC.length);
    setWinsPC(winsPC.length);

    setInProgressIC(inProgressIC.length);
    setBeIC(breakevensIC.length);
    setLossIC(lossesIC.length);
    setWinsIC(winsIC.length);

    setInProgressOptions(inProgressOptions.length);
    setBeOptions(breakevensOptions.length);
    setLossOptions(lossesOptions.length);
    setWinsOptions(winsOptions.length);

    setReturnDollarInvestor(parseFloat(returnDollarTotals_Investor_summed));
    setReturnDollarPersonal(parseFloat(returnDollarTotals_Personal_summed));
    setReturnDollarOptions(parseFloat(returnDollarTotals_Options_summed));

    setReturnPercentIC(parseFloat(sumTotalProfitLossPercentageIC));
    setReturnPercentPC(parseFloat(sumTotalProfitLossPercentagePC));
    setReturnPercentOptions(parseFloat(sumTotalProfitLossPercentageOptions));

    return virtualArray;
  };
  const getTrades = async (date, typeOfReview) => {
    const result = await DataFetcher("get-trades-to-reflect", {
      date: date,
      typeOfReviewQuery: typeOfReview,
      missedTrade: missedTrades ? true : false,
    });

    if (result) {
      let _trades = [];
      if (!missedTrades) {
        _trades = result.filter((trades) => trades.id?.includes("live-"));
      } else {
        _trades = result.filter((trades) =>
          trades.missedTrade?.includes("Yes")
        );
      }

      const calTrades = await runCalculations(_trades);
      setTrades(calTrades.reverse());
    }
  };
  const goBack = () => {
    setSingleReflectionView(false);
    setSingleJournalView(false);
    setOpenJournal(false);
    // handleScroll();
    localStorage.removeItem("reflection-to-view");
  };
  const handleQ1 = (event) => {
    setQ1(event.target.value);
    setQ2("");
    setQ3("");
    setQ4("");
    setWeekly("");
    setMonthly("");
    setAnnual("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleQ2 = (event) => {
    setQ1("");
    setQ2(event.target.value);
    setQ3("");
    setQ4("");
    setWeekly("");
    setMonthly("");
    setAnnual("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleQ3 = (event) => {
    setQ1("");
    setQ2("");
    setQ3(event.target.value);
    setQ4("");
    setWeekly("");
    setMonthly("");
    setAnnual("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleQ4 = (event) => {
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4(event.target.value);
    setWeekly("");
    setMonthly("");
    setAnnual("");
    setTrades([]);
    setHideTypeOf(true);
  };
  const handleWeekly = (event) => {
    setWeekly(event.target.value);
    setMonthly("");
    setAnnual("");
    setTrades([]);
    setHideTypeOf(true);
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4("");
  };
  const handleMonthly = (event) => {
    setMonthly(event.target.value);
    setWeekly("");
    setAnnual("");
    setTrades([]);
    setHideTypeOf(true);
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4("");
  };
  const handleAnnual = (event) => {
    setAnnual(event.target.value);
    setMonthly("");
    setWeekly("");
    setTrades([]);
    setHideTypeOf(true);
    setQ1("");
    setQ2("");
    setQ3("");
    setQ4("");
  };
  const deleteReflections = async () => {
    const schema = {
      _id: localStorage.getItem("reflection-to-view"),
    };
    const result = await DataFetcher("delete-reflection", schema);
    if (result) {
      const filteredArray = auth.reflections.filter(
        (x) => x._id !== localStorage.getItem("reflection-to-view")
      );
      auth.setReflections(filteredArray);
      throwMessage("success", "Deleted");
      goBack();
    }
  };
  const handleMissedTrades = (e) => {
    setMissedTrades(e.target.checked);
  };
  const setColor = () => {
    const mode = localStorage.getItem("equity-mode");
    switch (mode) {
      case "Personal Capital":
        return (
          <SavingsOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Investor Capital":
        return (
          <AccountBalanceOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Personal & Investor Capital":
        return (
          <JoinRightOutlinedIcon
            sx={{
              color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              transform: "rotate(180deg)",
              fontSize: "35px",
            }}
          />
        );
      case "Options":
        return (
          <HourglassTopIcon
            sx={{
              color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              fontSize: "35px",
            }}
          />
        );
      default:
        return (
          <MoreVertIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
    }
  };
  const handleMode = () => {
    if (mode === "Personal Capital") {
      localStorage.setItem("equity-mode", "Investor Capital");
      auth.setEquityTypeMode("Investor Capital");
    } else if (mode === "Investor Capital") {
      localStorage.setItem("equity-mode", "Personal & Investor Capital");
      auth.setEquityTypeMode("Personal & Investor Capital");
    } else if (mode === "Personal & Investor Capital") {
      localStorage.setItem("equity-mode", "Options");
      auth.setEquityTypeMode("Options");
    } else if (mode === "Options") {
      localStorage.setItem("equity-mode", "Personal Capital");
      auth.setEquityTypeMode("Personal Capital");
    }

    runCalculations(
      auth.trades,
      auth.backtestingMode,
      auth.backtestingMode ? localStorage.getItem("backtest-active") : ""
    );
  };
  const setItPercent = (
    mode,
    returnPercentPC,
    returnPercentIC,
    returnPercentOptions
  ) => {
    const pcic =
      returnPercentPC + returnPercentIC < 0
        ? "#f23645"
        : returnPercentPC + returnPercentIC > 0
        ? "#26a65d"
        : "#ED9E0E";

    const pc =
      returnPercentPC < 0
        ? "#f23645"
        : returnPercentPC > 0
        ? "#26a65d"
        : "#ED9E0E";

    const ic =
      returnPercentIC + returnPercentIC < 0
        ? "#f23645"
        : returnPercentIC + returnPercentIC > 0
        ? "#26a65d"
        : "#ED9E0E";

    const options =
      returnPercentOptions < 0
        ? "#f23645"
        : returnPercentOptions > 0
        ? "#26a65d"
        : "#ED9E0E";

    if (mode === "Personal Capital") {
      return pc;
    } else if (mode === "Investor Capital") {
      return ic;
    } else if (mode === "Options") {
      return options;
    } else {
      return pcic;
    }
  };

  const setItDollar = (
    mode,
    returnDollarPersonal,
    returnDollarInvestor,
    returnDollarOptions
  ) => {
    const pcic =
      returnDollarPersonal + returnDollarInvestor < 0
        ? "#f23645"
        : returnDollarPersonal + returnDollarInvestor > 0
        ? "#26a65d"
        : "#ED9E0E";

    const pc =
      returnDollarPersonal < 0
        ? "#f23645"
        : returnDollarPersonal > 0
        ? "#26a65d"
        : "#ED9E0E";

    const ic =
      returnDollarInvestor + returnDollarInvestor < 0
        ? "#f23645"
        : returnDollarInvestor + returnDollarInvestor > 0
        ? "#26a65d"
        : "#ED9E0E";

    const options =
      returnDollarOptions < 0
        ? "#f23645"
        : returnDollarOptions > 0
        ? "#26a65d"
        : "#ED9E0E";

    if (mode === "Personal Capital") {
      return pc;
    } else if (mode === "Investor Capital") {
      return ic;
    } else if (mode === "Options") {
      return options;
    } else {
      return pcic;
    }
  };

  useEffect(() => {
    if (typeOfReview) getTrades(date, typeOfReview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, missedTrades, mode]);

  const findReflection = () => {
    const SingleReflection = auth.reflections.find((reflection) =>
      reflection?._id.includes(localStorage.getItem("reflection-to-view"))
    );
    setDate(
      auth.reflections.find((reflection) =>
        reflection?._id.includes(localStorage.getItem("reflection-to-view"))
      ).date
    );
    if (SingleReflection) {
      getTrades(SingleReflection.date, SingleReflection.typeOfReview);
      setTypeOfReview(SingleReflection.typeOfReview);
      setQuestion_1(SingleReflection.question_1);
      setQuestion_2(SingleReflection.question_2);
      setQuestion_3(SingleReflection.question_3);
      setQuestion_4(SingleReflection.question_4);
      setQuestion_5(SingleReflection.question_5);
      setQuestion_6(SingleReflection.question_6);
      setQuestion_7(SingleReflection.question_7);
      setQuestion_8(SingleReflection.question_8);
      setId(SingleReflection._id);
      switch (SingleReflection.typeOfReview) {
        case "Weekly":
          setWeekly("Weekly");
          break;
        case "Monthly":
          setMonthly("Monthly");
          break;
        case "Annual":
          setAnnual("Annual");
          break;
        case "Q1":
          setQ1("Q1");
          break;
        case "Q2":
          setQ2("Q2");
          break;
        case "Q3":
          setQ3("Q3");
          break;
        case "Q4":
          setQ4("Q4");
          break;
        default:
          break;
      }
      setFinidng(false);
    }
  };
  useEffect(() => {
    localStorage.setItem("reflection-saved", true);
    findReflection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callbackDelete = useCallback((props) => {
    deleteReflections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const callback = useCallback((props) => {
  //   setTypeOfReview("");
  //   setChangeDate(true);
  //   setHideTypeOf(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const bindDelete = useLongPress(callbackDelete, { threshold: 3000 });
  // const bind = useLongPress(callback, { threshold: 1000 });

  const changeReflection = (direction, pass) => {
    let next;
    let back;

    let array = pass;
    if (pass.length > 1) {
      array = array.sort((a, b) => {
        return moment(a.date).diff(b.date);
      });

      const foundIndex = array.findIndex((x) => x._id === id);

      next = foundIndex + 1;
      back = foundIndex - 1;

      if (foundIndex === 0) {
        back = array.length - 1;
      }
      if (foundIndex + 1 === array.length) {
        next = 0;
      }

      if (direction === "Next") {
        setTypeOfReview(array[next]?.typeOfReview);
        switch (array[next]?.typeOfReview) {
          case "Weekly":
            setWeekly("Weekly");
            setMonthly("");
            setAnnual("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Monthly":
            setMonthly("Monthly");
            setAnnual("");
            setWeekly("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Annual":
            setAnnual("Annual");
            setMonthly("");
            setWeekly("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Q1":
            setQ1("Q1");
            setMonthly("");
            setAnnual("");
            setWeekly("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Q2":
            setQ2("Q2");
            setQ1("");
            setMonthly("");
            setAnnual("");
            setWeekly("");
            setQ3("");
            setQ4("");
            break;
          case "Q3":
            setQ3("Q3");
            setQ2("Q2");
            setQ1("");
            setMonthly("");
            setWeekly("");
            setAnnual("");
            setQ4("");
            break;
          case "Q4":
            setQ4("Q4");
            setQ3("Q3");
            setQ2("Q2");
            setQ1("");
            setMonthly("");
            setAnnual("");
            setWeekly("");
            break;
          default:
            break;
        }
        setId(array[next]?._id);
        localStorage.setItem("reflection-to-view", array[next]?._id);
        setQuestion_1(array[next]?.question_1);
        setQuestion_2(array[next]?.question_2);
        setQuestion_3(array[next]?.question_3);
        setQuestion_4(array[next]?.question_4);
        setQuestion_5(array[next]?.question_5);
        setQuestion_6(array[next]?.question_6);
        setQuestion_7(array[next]?.question_7);
        setQuestion_8(array[next]?.question_8);
        setDate(array[next]?.date);
      } else if (direction === "Back") {
        setTypeOfReview(array[back]?.typeOfReview);
        switch (array[back]?.typeOfReview) {
          case "Weekly":
            setWeekly("Weekly");
            setMonthly("");
            setAnnual("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Monthly":
            setMonthly("Monthly");
            setAnnual("");
            setWeekly("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Annual":
            setAnnual("Annual");
            setMonthly("");
            setWeekly("");
            setQ1("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Q1":
            setQ1("Q1");
            setMonthly("");
            setAnnual("");
            setWeekly("");
            setQ2("");
            setQ3("");
            setQ4("");
            break;
          case "Q2":
            setQ2("Q2");
            setQ1("");
            setAnnual("");
            setMonthly("");
            setWeekly("");
            setQ3("");
            setQ4("");
            break;
          case "Q3":
            setQ3("Q3");
            setQ2("Q2");
            setQ1("");
            setMonthly("");
            setAnnual("");
            setWeekly("");
            setQ4("");
            break;
          case "Q4":
            setQ4("Q4");
            setQ3("Q3");
            setQ2("Q2");
            setQ1("");
            setMonthly("");
            setAnnual("");
            setWeekly("");
            break;
          default:
            break;
        }
        setId(array[back]?._id);
        localStorage.setItem("reflection-to-view", array[back]?._id);
        setQuestion_1(array[back]?.question_1);
        setQuestion_2(array[back]?.question_2);
        setQuestion_3(array[back]?.question_3);
        setQuestion_4(array[back]?.question_4);
        setQuestion_5(array[back]?.question_5);
        setQuestion_6(array[back]?.question_6);
        setQuestion_7(array[back]?.question_7);
        setQuestion_8(array[back]?.question_8);

        setDate(array[back]?.date);
      }
    }
  };

  return !finding ? (
    <Box
      sx={{
        overflowY: "scroll",
        overflowX: "hidden",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        width: "100%",
        maxWidth: "1920px",
        height: "100%",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        padding: "20px 30px 20px 30px",
        margin: "0 auto",
      }}
    >
      <h3
        // {...bind()}
        style={{
          textAlign: "center",
          fontWeight: "300",
          borderBottom:
            theme[0] === "dark"
              ? " 1px solid #FCFCFF"
              : "1px solid rgba(37,37,37)",
          maxWidth: "750px",
          margin:
            hideTypeOf && trades.length === 0 && width <= 800
              ? "3.5em auto 5em auto"
              : hideTypeOf && trades.length === 0 && width > 800
              ? "1em auto 5em auto"
              : width <= 800
              ? "3.5em auto 50px auto"
              : width > 800
              ? "1em auto 50px auto"
              : "",

          padding: "0 0 15px 0",
          cursor: "pointer",
          fontSize: "26px",
        }}
      >
        {" "}
        {weekly
          ? "Weekly"
          : monthly
          ? "Monthly"
          : Annual
          ? "Annual"
          : q1
          ? "Q1 Jan-Mar"
          : q2
          ? "Q2 Apr-Jun"
          : q3
          ? "Q3 Jul-Sep"
          : q4
          ? "Q4 Oct-Dec"
          : "New"}{" "}
        Reflection{" "}
        <span>
          {weekly
            ? moment(date).format("ddd Do MMM")
            : monthly
            ? moment(date).format("MMMM YYYY")
            : Annual
            ? moment(date).format("YYYY")
            : q1
            ? ""
            : q2
            ? ""
            : q3
            ? ""
            : q4
            ? ""
            : moment(date).format("ddd Do MMM")}
        </span>{" "}
      </h3>{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "0.7em",
          margin: width <= 800 ? "5em 1em 0 1em" : "0em 1em 0 1em",
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        }}
      >
        <p
          onClick={() => changeReflection("Back", auth.reflections)}
          onMouseEnter={() => setIsHoveringBack(true)}
          onMouseLeave={() => setIsHoveringBack(false)}
          style={{
            cursor: "pointer",
            position: "fixed",
            left: "60px",
            top: "35px",
            color: isHoveringBack ? "#ED9E0E" : "",
          }}
        >
          Back
        </p>
        <p
          onMouseEnter={() => setIsHoveringNext(true)}
          onMouseLeave={() => setIsHoveringNext(false)}
          style={{
            cursor: "pointer",
            position: "fixed",
            right: "60px",
            top: "35px",
            color: isHoveringNext ? "#ED9E0E" : "",
          }}
          onClick={() => changeReflection("Next", auth.reflections)}
        >
          Next
        </p>
      </div>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ margin: "0 auto" }}
      >
        {!hideTypeOf ? (
          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 0 2.8em 0",
            }}
          >
            <FormControl>
              <FormLabel
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  textAlign: "center",
                }}
                id=""
              >
                Reflection Type
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="go-to"
                defaultValue=""
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                }}
                onChange={(e) => {
                  setTypeOfReview(e.target.value);
                }}
              >
                <FormControlLabel
                  value="Weekly"
                  control={
                    <Radio
                      onChange={handleWeekly}
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    />
                  }
                  label="Weekly"
                />
                <FormControlLabel
                  value="Monthly"
                  control={
                    <Radio
                      onChange={handleMonthly}
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    />
                  }
                  label="Monthly"
                />
                <FormControlLabel
                  value="Annual"
                  control={
                    <Radio
                      onChange={handleAnnual}
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    />
                  }
                  label="Annual"
                />

                {moment().quarter() === 1 && (
                  <FormControlLabel
                    value="Q1"
                    control={
                      <Radio
                        onChange={handleQ1}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label="Q1"
                  />
                )}
                {moment().quarter() === 2 && (
                  <FormControlLabel
                    value="Q2"
                    control={
                      <Radio
                        onChange={handleQ2}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label="Q2"
                  />
                )}

                {moment().quarter() === 3 && (
                  <FormControlLabel
                    value="Q3"
                    control={
                      <Radio
                        onChange={handleQ3}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label="Q3"
                  />
                )}

                {moment().quarter() === 4 && (
                  <FormControlLabel
                    value="Q4"
                    control={
                      <Radio
                        onChange={handleQ4}
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      />
                    }
                    label="Q4"
                  />
                )}
              </RadioGroup>
            </FormControl>

            {/* <CustomMultiTextField
                size="small"
                rows={3}
                variant="standard"
                // value={higherTimeframeName}
                // label={higherTimeframeName ? "" : "Enter Timeframe"}
                onChange={(e) => {
                  // setHigherTimeframeName(e.target.value);
                }}
                fontSize="20px"
                // disableUnderline={true}
              /> */}
          </Grid>
        ) : null}
        {trades.length === 0 && typeOfReview && hideTypeOf && (
          <Grid
            item
            xs={12}
            style={{ margin: "-4em auto 2em auto", textAlign: "center" }}
          >
            <p
              style={{
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                fontSize: "0.7em",
                fontWeight: "300",
              }}
            >
              {!missedTrades
                ? "You didn't take any trades this"
                : "No missed trades this"}{" "}
              {weekly
                ? "week"
                : monthly
                ? "month"
                : Annual
                ? "year"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}{" "}
              on {mode}
            </p>
          </Grid>
        )}
        {changeDate && typeOfReview ? (
          <Grid item xs={2} style={{ margin: "0 0 2em 0" }}>
            <CustomDatePicker
              size="small"
              label="Calendar Event Date"
              value={date}
              onChange={(newValue) => {
                setChangeDate(false);
                setDate(newValue.$d);
              }}
            />
          </Grid>
        ) : null}

        {trades.length > 0 ? (
          <>
            <Grid
              style={{
                margin: "0 auto 6em auto",
                height: "500px",
                width: "95%",
              }}
            >
              <Grid
                item
                xs={width <= 500 ? 12 : 8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  margin: "-1em auto 2em auto",
                  flexDirection: width <= 500 ? "column" : "row",
                  fontSize: "0.75em",
                }}
              >
                <p style={{ color: "#26a65d" }}>
                  <span
                    style={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      fontWeight: "300",
                    }}
                  >
                    Wins:
                  </span>{" "}
                  {mode === "Personal Capital"
                    ? winsPC
                    : mode === "Investor Capital"
                    ? winsIC
                    : mode === "Options"
                    ? winsOptions
                    : winsPC + winsIC}
                </p>
                <p style={{ color: "#f23645" }}>
                  <span
                    style={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      fontWeight: "300",
                    }}
                  >
                    Losses:
                  </span>{" "}
                  {mode === "Personal Capital"
                    ? lossPC
                    : mode === "Investor Capital"
                    ? lossIC
                    : mode === "Options"
                    ? lossOptions
                    : lossPC + lossIC}
                </p>
                <p style={{ color: "#ED9E0E" }}>
                  <span
                    style={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      fontWeight: "300",
                    }}
                  >
                    Breakevens:
                  </span>{" "}
                  {mode === "Personal Capital"
                    ? bePC
                    : mode === "Investor Capital"
                    ? beIC
                    : mode === "Options"
                    ? beOptions
                    : bePC + beIC}
                </p>
                <p
                  style={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    fontWeight: "300",
                  }}
                >
                  <span>In Progress:</span>{" "}
                  {mode === "Personal Capital"
                    ? inProgressPC
                    : mode === "Investor Capital"
                    ? inProgressIC
                    : mode === "Options"
                    ? inProgressOptions
                    : inProgressPC + inProgressIC}
                </p>{" "}
                {mode === "Personal & Investor Capital" ? (
                  <p>
                    <span
                      style={{
                        color:
                          returnPercentPC < 0
                            ? "#f23645"
                            : returnPercentPC > 0
                            ? "#26a65d"
                            : "#ED9E0E",
                        fontWeight: "300",
                      }}
                    >
                      {Math.abs(returnPercentPC.toFixed(2))} %
                    </span>{" "}
                    /{" "}
                    <span
                      style={{
                        color:
                          returnPercentIC < 0
                            ? "#f23645"
                            : returnPercentIC > 0
                            ? "#26a65d"
                            : "#ED9E0E",
                        fontWeight: "300",
                      }}
                    >
                      {Math.abs(returnPercentIC.toFixed(2))} %
                    </span>
                  </p>
                ) : (
                  <p
                    style={{
                      color: setItPercent(
                        mode,
                        returnPercentPC,
                        returnPercentIC,
                        returnPercentOptions
                      ),
                      fontWeight: "300",
                    }}
                  >
                    {mode === "Personal Capital"
                      ? `${Math.abs(returnPercentPC.toFixed(2))} %`
                      : mode === "Investor Capital"
                      ? `${Math.abs(returnPercentIC.toFixed(2))} %`
                      : mode === "Options"
                      ? `${Math.abs(returnPercentOptions.toFixed(2))} %`
                      : ""}
                  </p>
                )}
                {mode === "Personal & Investor Capital" ? (
                  <p>
                    <span
                      style={{
                        color:
                          returnDollarPersonal < 0
                            ? "#f23645"
                            : returnDollarPersonal > 0
                            ? "#26a65d"
                            : "#ED9E0E",
                        fontWeight: "300",
                      }}
                    >
                      £ {Math.abs(returnDollarPersonal.toFixed(2))}
                    </span>{" "}
                    /{" "}
                    <span
                      style={{
                        color:
                          returnDollarInvestor < 0
                            ? "#f23645"
                            : returnDollarInvestor > 0
                            ? "#26a65d"
                            : "#ED9E0E",
                        fontWeight: "300",
                      }}
                    >
                      $ {Math.abs(returnDollarInvestor.toFixed(2))}
                    </span>
                  </p>
                ) : (
                  <p
                    style={{
                      color: setItDollar(
                        mode,
                        returnDollarPersonal,
                        returnDollarInvestor,
                        returnDollarOptions
                      ),
                      fontWeight: "300",
                    }}
                  >
                    {mode === "Personal Capital"
                      ? `£ ${Math.abs(returnDollarPersonal.toFixed(2))}`
                      : mode === "Investor Capital"
                      ? `$ ${Math.abs(returnDollarInvestor.toFixed(2))}`
                      : mode === "Options"
                      ? `$ ${Math.abs(returnDollarOptions.toFixed(2))}`
                      : ""}
                  </p>
                )}
              </Grid>

              <DataGrid
                sx={{
                  width: "100%",
                  height: "500px",
                  "& .MuiSvgIcon-root": {
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  },

                  backgroundColor:
                    theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  "& .MuiDataGrid-cell": {
                    cursor: "pointer",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  },
                  "& .MuiTablePagination-actions": {
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor:
                      theme[0] === "dark"
                        ? "rgb(90, 90, 90)"
                        : "rgb(210, 210, 210)",
                  },
                }}
                rows={trades}
                columns={columnsReflections(
                  setSingleJournalView,
                  setSingleReflectionView,
                  setOpenJournal,
                  setSingleForecastView,
                  handleCloseNewReflection,
                  theme
                )}
                autoPageSize
                rowHeight={53}
                // disableSelectionOnClick
              />
            </Grid>
          </>
        ) : null}

        <Grid
          container
          spacing={7}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: "100%", fontSize: "16px" }}
        >
          {weekly || monthly || Annual || q1 || q2 || q3 || q4 ? (
            <Grid
              item
              xs={12}
              style={{
                marginTop: width <= 500 ? "18em" : "-2em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                sx={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  margin: "0 0em 0 0em",
                }}
                control={<Switch />}
                label={missedTrades ? "Missed Trades" : "Live Trades"}
                checked={missedTrades}
                onChange={handleMissedTrades}
                name={"Missed Trades"}
              />
              <Tooltip
                title={mode}
                fontSize="large"
                sx={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  cursor: "pointer",
                }}
              >
                <IconButton
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    margin: "0 0.5em 0 0.5em",
                  }}
                  onClick={handleMode}
                >
                  {setColor()}
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          <Grid item xs={5}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              Things I will do more of next{" "}
              {weekly
                ? "week"
                : monthly
                ? "month"
                : Annual
                ? "year"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_1}
              onChange={(e) => setQuestion_1(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>
          <Grid item xs={5}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              Things I will do less of next{" "}
              {weekly
                ? "week"
                : monthly
                ? "month"
                : Annual
                ? "year"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_2}
              onChange={(e) => setQuestion_2(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>

          <Grid item xs={5} style={{ marginTop: "1em" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              What are the negatives/cons about the trades I took this{" "}
              {weekly
                ? "week"
                : monthly
                ? "month"
                : Annual
                ? "year"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_3}
              onChange={(e) => setQuestion_3(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: "1em" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              What are the positives/pros about the trades I took this{" "}
              {weekly
                ? "week"
                : monthly
                ? "month"
                : Annual
                ? "year"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_4}
              onChange={(e) => setQuestion_4(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: "1em" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              How do I feel about my work ethic? Do my actions align with my
              aspirations and goals?
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_5}
              onChange={(e) => setQuestion_5(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: "1em" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              How do I feel about myself this{" "}
              {weekly
                ? "week"
                : monthly
                ? "month"
                : Annual
                ? "year"
                : q1
                ? "quarter"
                : q2
                ? "quarter"
                : q3
                ? "quarter"
                : q4
                ? "quarter"
                : ""}
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_6}
              onChange={(e) => setQuestion_6(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>

          <Grid item xs={5} style={{ marginTop: "1em" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              How were my emotions when I was in trades? How were my emotions
              when/if I missed trades
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_7}
              onChange={(e) => setQuestion_7(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: "1em" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                paddingBottom: "10px",
                width: width <= 500 ? "100%" : "70%",
                margin: "0 auto 23px auto",
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                fontWeight: "300",
              }}
            >
              Anything else I want to add (other areas of my life, things I am
              grateful for etc.)
            </p>
            <CustomMultiTextField
              size="small"
              rows={5}
              variant="standard"
              value={question_8}
              onChange={(e) => setQuestion_8(e.target.value)}
              disableUnderline={true}
              fontSize={"14px"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 0 1em 0",
            }}
          >
            <Tooltip title="Hold for 3 Seconds To Delete">
              <DeleteOutlinedIcon
                fontSize="large"
                sx={{
                  color: "#f23645",
                  cursor: "pointer",
                }}
                {...bindDelete()}
                label={"Delete"}
              />
            </Tooltip>

            <Tooltip title="Close">
              <CloseIcon
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  cursor: "pointer",
                  margin: "0 0.8em 0 0.8em",
                }}
                fontSize="large"
                onClick={() => goBack()}
              />
            </Tooltip>

            <Tooltip title="Save">
              <SaveIcon
                sx={{
                  color: formSubmitted ? "lightGrey" : "#26a65d",
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={() => {
                  handleSave();
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    "Loading"
  );
};

export default SingleReflection;
