import { useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import markets from "../../lib/markets";
import DatePicker from "../dateTimePickers/datePicker";
import dayjs from "dayjs";
import moment from "moment";
import useWindowDimensions from "../../utils/width";

const InstrumentAnalyticsByDate = ({ trades, runSpeficCal, theme }) => {
  const [pair, setPair] = useState("");
  const [start, setStart] = useState(dayjs(new Date()));
  const [end, setEnd] = useState(dayjs(new Date()));
  const style = { color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)" };
  const { width } = useWindowDimensions();
  const win = "#26a65d";
  const loss = "#f23645";
  const breakeven = "#ED9E0E";

  return (
    <div
      style={{
        padding: "0.7em",
        borderRadius: "10px",
        width: "100%",
        maxWidth: "770px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4
        style={{
          margin: "10px auto 10px auto",
          width: "100%",
          fontSize: width <= 700 ? "16px" : "18px",
          fontWeight: "400",
          borderBottom: `1px solid ${
            theme[0] === "dark" ? "#FCFCFF" : "black"
          }`,
          paddingBottom: "10px",
          textAlign: "center",
        }}
      >
        Instrument Analytics By Date
      </h4>
      <div
        style={{
          margin: "30px auto 10px auto",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "40%",
          }}
        >
          <DatePicker
            size="small"
            label="Start"
            value={start}
            onChange={(newValue) => setStart(newValue.$d)}
          />
        </div>
        <div
          style={{
            width: "40%",
          }}
        >
          <DatePicker
            defaultValue={end}
            size="small"
            label="End"
            value={end}
            onChange={(newValue) => setEnd(newValue.$d)}
          />
        </div>
      </div>
      <table
        style={{
          tableLayout: "fixed",
          borderCollapse: "collapse",
          width: "100%",
          height: "120px",
          fontSize: width <= 700 ? "13px" : "18px",
          margin: "0 auto",
        }}
      >
        <thead
          className="tableHoverEffectOff"
          style={{ fontSize: width <= 700 ? "13px" : "16px", height: "50px" }}
        >
          <tr>
            <th style={{ fontWeight: "400", width: "150px" }}>Instrument</th>
            <th style={{ fontWeight: "400", color: win }}>Win</th>
            <th style={{ fontWeight: "400", color: breakeven }}>BE</th>
            <th style={{ fontWeight: "400", color: loss }}>Loss</th>
            <th style={{ fontWeight: "400" }}># Trades</th>
            <th style={{ fontWeight: "400" }}>Return %</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              <FormControl fullWidth size={"small"}>
                <InputLabel
                  sx={{
                    color:
                      theme[0] === "dark"
                        ? "#FCFCFF !important"
                        : "rgba(37,37,37) !important",
                    borderOutline:
                      theme[0] === "dark"
                        ? "#FCFCFF !important"
                        : "rgba(37,37,37) !important",
                  }}
                >
                  Instrument
                </InputLabel>

                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor:
                          theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                        maxHeight: "450px",

                        "& .MuiMenuItem-root": {
                          padding: 1.5,
                          justifyContent: "center",
                        },
                      },
                    },
                  }}
                  defaultValue=""
                  fullWidth
                  sx={{
                    ".MuiSvgIcon-root": {
                      fill:
                        theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    },
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",

                    "& fieldset": {
                      // - The <fieldset> inside the Input-root

                      borderColor:
                        theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
                    },
                    "&:hover fieldset": {
                      borderColor:
                        theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                    },
                    "&.Mui-focused fieldset": {
                      // - Set the Input border when parent is focused
                      borderColor:
                        theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                    },

                    input: {
                      margin: "0",
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    },
                  }}
                  value={pair}
                  label="Instrument"
                  onChange={(event) => {
                    setPair(event.target.value);
                  }}
                >
                  {markets.map((m) => {
                    if (m.label === "Forex") {
                      return (
                        <ListSubheader
                          key={m.label}
                          sx={{
                            textAlign: "center",
                            bgcolor:
                              theme[0] === "dark"
                                ? "rgba(40, 42, 46)"
                                : "#FCFCFF",
                            color: "#ED9E0E",
                            fontSize: "0.6em",
                          }}
                        >
                          Forex
                        </ListSubheader>
                      );
                    } else if (m.label === "Indices") {
                      return (
                        <ListSubheader
                          key={m.label}
                          sx={{
                            textAlign: "center",
                            bgcolor:
                              theme[0] === "dark"
                                ? "rgba(40, 42, 46)"
                                : "#FCFCFF",
                            color: "#ED9E0E",
                            fontSize: "0.6em",
                          }}
                        >
                          Indices
                        </ListSubheader>
                      );
                    } else if (m.label === "Commodities") {
                      return (
                        <ListSubheader
                          key={m.label}
                          sx={{
                            textAlign: "center",
                            bgcolor:
                              theme[0] === "dark"
                                ? "rgba(40, 42, 46)"
                                : "#FCFCFF",
                            color: "#ED9E0E",
                            fontSize: "0.6em",
                          }}
                        >
                          Commodities
                        </ListSubheader>
                      );
                    } else if (m.label === "Crypto") {
                      return (
                        <ListSubheader
                          key={m.label}
                          sx={{
                            textAlign: "center",
                            bgcolor:
                              theme[0] === "dark"
                                ? "rgba(40, 42, 46)"
                                : "#FCFCFF",
                            color: "#ED9E0E",
                            fontSize: "0.6em",
                          }}
                        >
                          Crypto
                        </ListSubheader>
                      );
                    } else {
                      return (
                        <MenuItem key={m.label} sx={style} value={m.ticker}>
                          {m.label}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </th>

            <td style={{ color: win }}>
              {
                trades.filter(
                  (p) =>
                    p.pair === pair &&
                    (p.outcomePC === "Win" ||
                      p.outcomeIC === "Win" ||
                      p.outcomeOptions === "Win") &&
                    moment(moment(p.entryDate, "DD/MM/YY")).isBetween(
                      moment(start),
                      moment(end)
                    )
                ).length
              }
            </td>

            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (p) =>
                    p.pair === pair &&
                    (p.outcomePC === "Breakeven" ||
                      p.outcomeIC === "Breakeven" ||
                      p.outcomeOptions === "Breakeven") &&
                    moment(moment(p.entryDate, "DD/MM/YY")).isBetween(
                      moment(start),
                      moment(end)
                    )
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (p) =>
                    p.pair === pair &&
                    (p.outcomePC === "Loss" ||
                      p.outcomeIC === "Loss" ||
                      p.outcomeOptions === "Loss") &&
                    moment(moment(p.entryDate, "DD/MM/YY")).isBetween(
                      moment(start),
                      moment(end)
                    )
                ).length
              }
            </td>
            <td>
              {
                trades.filter(
                  (p) =>
                    p.pair === pair &&
                    moment(moment(p.entryDate, "DD/MM/YY")).isBetween(
                      moment(start),
                      moment(end)
                    )
                ).length
              }
            </td>
            {runSpeficCal(
              trades.filter(
                (p) =>
                  p.pair === pair &&
                  moment(moment(p.entryDate, "DD/MM/YY")).isBetween(
                    moment(start),
                    moment(end)
                  )
              )
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InstrumentAnalyticsByDate;
