import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AuthContext from "../../lib/auth-context";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Monthly = ({ calculateFields, theme, selected }) => {
  const auth = useContext(AuthContext);
  const backTestId = localStorage.getItem("backtest-active");
  const backtestingMode = auth.backtestingMode;
  const [percentReturn, setPercentReturn] = useState([]);
  const [moneyReturn, setMoneyReturn] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const backtestActive = localStorage.getItem("backtest-active");

  useEffect(() => {
    workOutCurvePoints(localStorage.getItem("year"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, mode, backtestActive, localStorage.getItem("year")]);

  const Options = mode === "Options";
  const all = mode === "Personal & Investor Capital";
  const personal = mode === "Personal Capital";
  const investor = mode === "Investor Capital";

  const workOutCurvePoints = async (years) => {
    let trades = await calculateFields(
      auth.trades,
      backtestingMode,
      backtestingMode ? backTestId : "",
      false,
      true
    );

    if (backtestingMode) {
      if (backTestId === "Missed Trades") {
        trades = trades.filter((t) => t.missedTrade === "Yes");
      } else {
        trades = trades.filter((t) => t.testId === backTestId);
      }
    }

    if (trades.length > 0);

    trades = trades.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
    trades = trades.filter(
      (t) => moment(t.entryDate, "DD/MM/YYYY").format("Y") === years
    );

    let january = [];
    let february = [];
    let march = [];
    let april = [];
    let may = [];
    let june = [];
    let july = [];
    let august = [];
    let september = [];
    let october = [];
    let november = [];
    let december = [];

    trades.forEach((trade) => {
      const month = moment(trade.entryDate, "DD/MM/YYYY").format("M");
      if (month === "1") {
        january.push(trade);
      } else if (month === "2") {
        february.push(trade);
      } else if (month === "3") {
        march.push(trade);
      } else if (month === "4") {
        april.push(trade);
      } else if (month === "5") {
        may.push(trade);
      } else if (month === "6") {
        june.push(trade);
      } else if (month === "7") {
        july.push(trade);
      } else if (month === "8") {
        august.push(trade);
      } else if (month === "9") {
        september.push(trade);
      } else if (month === "10") {
        october.push(trade);
      } else if (month === "11") {
        november.push(trade);
      } else if (month === "12") {
        december.push(trade);
      }
    });

    // strip non used fields from the trades array

    january = january.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    february = february.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    march = march.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    april = april.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    may = may.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    june = june.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    july = july.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    august = august.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    september = september.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    october = october.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    november = november.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });
    december = december.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
        profitLossPercentageOptions: t.profitLossPercentageOptions,
        profitLossDollarOptions: t.profitLossDollarOptions,
        rollingBalancePC: t.rollingBalancePC,
        rollingBalanceIC: t.rollingBalanceIC,
        rollingBalanceOptions: t.rollingBalanceOptions,
      };

      return schema;
    });

    const returnArray = (index) => {
      switch (index) {
        case 0:
          return january;
        case 1:
          return february;
        case 2:
          return march;
        case 3:
          return april;
        case 4:
          return may;
        case 5:
          return june;
        case 6:
          return july;
        case 7:
          return august;
        case 8:
          return september;
        case 9:
          return october;
        case 10:
          return november;
        case 11:
          return december;

        default:
          return [];
      }
    };

    // percentage
    let monthlyResult = [];
    let totalForEachMonth = [];
    // money
    let monthlyResultDollar = [];

    let counter;

    if (Number(years) < moment().year()) {
      counter = 11;
    } else {
      counter = moment().month();
    }

    let balanceArray = [];
    let array_of_years_traded = auth.allYearsOfTrades;
    let balance;
    let percent;

    const firstYear = array_of_years_traded[0];
    const user = JSON.parse(localStorage.getItem("user"));

    if (personal) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalancePC);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesPC"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );

        // totalForEachMonth.push(
        //   JSON.parse(localStorage.getItem("rollingPercentsPC"))?.find(
        //     (element) =>
        //       element.title.includes(Number(localStorage.getItem("year")) - 1)
        //   )?.value
        // );
      }
    } else if (investor) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalanceIC);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesIC"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );

        // totalForEachMonth.push(
        //   JSON.parse(localStorage.getItem("rollingPercentsIC"))?.find(
        //     (element) =>
        //       element.title.includes(Number(localStorage.getItem("year")) - 1)
        //   )?.value
        // );
      }
    } else if (Options) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalanceOptions);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesOptions"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );

        // totalForEachMonth.push(
        //   JSON.parse(localStorage.getItem("rollingPercentsOptions"))?.find(
        //     (element) =>
        //       element.title.includes(Number(localStorage.getItem("year")) - 1)
        //   )?.value
        // );
      }
    }

    for (let index = 0; index < counter + 1; index++) {
      //------------------------Percent--------------------------//

      percent = returnArray(index).map((trade) =>
        personal
          ? parseFloat(trade.profitLossPercentagePC)
          : investor
          ? parseFloat(trade.profitLossPercentageIC)
          : Options
          ? parseFloat(trade.profitLossPercentageOptions)
          : 0
      );

      percent =
        percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
      totalForEachMonth.push(percent);
      monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));

      //-------------------------Dollar-------------------------//

      if (personal) {
        balance =
          returnArray(index)[returnArray(index).length - 1]?.rollingBalancePC;
      } else if (investor) {
        balance =
          returnArray(index)[returnArray(index).length - 1]?.rollingBalanceIC;
      } else if (Options) {
        balance =
          returnArray(index)[returnArray(index).length - 1]
            ?.rollingBalanceOptions;
      }

      if (balance) {
        balanceArray.push(balance);
      } else if (balance === undefined) {
        balance = balanceArray[balanceArray.length - 1];
      }

      monthlyResultDollar.push(balance);

      //--------------------------------------------------//
    }
    setPercentReturn(monthlyResult);
    setMoneyReturn(monthlyResultDollar);
  };
  const options = {
    responsive: true,
    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

    interaction: {
      mode: "index",
      intersect: false,
    },

    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },

    scales: {
      x: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        display: true,
        position: "bottom",
        grid: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
      y1: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
    },
  };
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: personal
          ? "PC Percentage"
          : investor
          ? "IC Percentage"
          : all
          ? "PIC Percentage"
          : "",
        data: percentReturn,
        borderColor: "blue",
        backgroundColor: "blue",
        yAxisID: "y1",
      },
      {
        label: personal
          ? "PC Equity"
          : investor
          ? "IC Equity"
          : all
          ? "PIC Equity"
          : "",
        data: moneyReturn,
        borderColor: "red",
        backgroundColor: "red",
        yAxisID: "y",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Monthly;
