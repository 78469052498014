import { useState, useEffect, useContext, useRef } from "react";

import moment from "moment";
import AuthContext from "../../lib/auth-context";

import markets from "../../lib/markets";
import throwMessage from "../../utils/throwMessage";

import { DataFetcher } from "../../utils/dataFetcher";

const Logic = () => {
  const auth = useContext(AuthContext);

  const setBalances = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return {
      startingBalanceIC: parseFloat(user?.startingBalanceIC),
      startingBalancePC: parseFloat(user?.startingBalancePC),
      startingBalanceOptions: parseFloat(user?.startingBalanceOptions),
    };
  };

  const [open, setOpen] = useState(false);
  const [openCal, setOpenCal] = useState(false);
  const [openNewTest, setOpenNewTest] = useState(false);
  const [openNewDeposit, setOpenNewDeposit] = useState(false);
  const [openJournal, setOpenJournal] = useState(false);
  const [equityCurve, setEquityCurve] = useState(
    localStorage.getItem("equityCurve") === "true" ? true : false
  );

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("pre-trade-checklist", false);
  };
  const handleCloseCal = (e) => {
    setOpenCal(false);
  };
  const handleOpenCal = () => {
    setOpenCal(true);
  };

  const handleOpenBacktest = () => setOpenNewTest(true);
  const handleCloseBacktest = () => setOpenNewTest(false);

  const handleOpenNewDeposit = () => setOpenNewDeposit(true);
  const handleCloseNewDeposit = () => setOpenNewDeposit(false);

  const handleOpenJournal = (data) => {
    if (data?.journal) {
      localStorage.setItem("journal-portal", JSON.stringify(data.journal));
      localStorage.setItem("trade-portal", JSON.stringify(data.trade));
      setOpenJournal(true);
    } else {
      const schema = {
        journalID: data.journalID,
        trade_id: data._id,
      };
      localStorage.setItem("journal-portal", JSON.stringify(schema));
      localStorage.setItem("trade-portal", JSON.stringify(data));
      setOpenJournal(true);
    }
  };
  const handleCloseJournal = () => {
    localStorage.removeItem("journal-portal");
    localStorage.removeItem("trade-portal");
    setOpenJournal(false);
  };

  const [remove, setRemove] = useState(false);

  let newTransactionIC;
  let noteIC;

  let newTransactionPC;
  let notePC;

  let newTransactionOptions;
  let noteOptions;

  const [realisedPercentage, setRealisedPercentage] = useState(0);
  const [realisedReturn, setRealisedReturn] = useState(0);

  const [realisedPercentageBacktest, setRealisedPercentageBacktest] =
    useState(0);

  const [realisedReturnBacktest, setRealisedReturnBacktest] = useState(0);

  const [selectedArchivedTests, setSelectedArchivedTests] = useState("");
  const [allbackTestSchemas, setAllbackTestSchemas] = useState([]);
  const [selectedArchivedTestsBoolean, setSelectedArchivedTestsBoolean] =
    useState(false);
  const [showSpecficTest, setShowSpecficTest] = useState("");
  const [toRemove, setToRemove] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  let startingBalanceIC = setBalances().startingBalanceIC;
  let startingBalancePC = setBalances().startingBalancePC;
  let startingBalanceOptions = setBalances().startingBalanceOptions;

  // let startingBalanceOptions = setBalances().startingBalanceOptions

  // let balanceOptions = startingBalanceOptions;
  let balanceIC = startingBalanceIC;
  let balancePC = startingBalancePC;
  let balanceOptions = startingBalanceOptions;

  let virtualFields = [];
  let returnPercentTotalsPC = [];
  let returnPercentTotalsIC = [];
  let returnPercentTotalsOptions = [];
  let returnDollarTotalsPersonal = [];
  let returnDollarTotalsInvestor = [];

  const effectRan = useRef(false);

  useEffect(() => {
    // generateRandomTrade("live", 20, "", false);
    // generateRandomTrade("backtest", 20, "Head n Shoulders", false);

    if (effectRan.current === false) {
      localStorage.setItem("missed-trade", "No");

      if (localStorage.getItem("trade-to-view")) {
        getTrades(localStorage.getItem("trade-to-view"));
      } else {
        getTrades();
      }
      setAllbackTestSchemas(auth.backtests);

      return () => {
        effectRan.current = true;
        localStorage.removeItem("trade-to-view");
        localStorage.removeItem("backtest-active");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionsCalulator = async (trades, trade, index) => {
    let transactions = JSON.parse(localStorage.getItem("user")).transactions;

    const previous_trade = new Date(trades[index - 1]?.created);
    const current_trade = new Date(trade?.created);

    let schemaIC = [];
    let schemaPC = [];
    let schemaOptions = [];

    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];
      const amount = parseFloat(transaction.transanctionAmount);
      const note = transaction.transanctionNotes;
      const reason = transaction.transanctionReason;
      const type = transaction.transactionType;

      if (reason === "Investor") {
        if (
          new Date(transaction.date) > previous_trade &&
          new Date(transaction.date) < current_trade
        ) {
          schemaIC.push({
            amount: amount,
            note: note,
            reason: reason,
            type: type,
          });
        }
      }
      if (reason === "Personal") {
        if (
          new Date(transaction.date) > previous_trade &&
          new Date(transaction.date) < current_trade
        ) {
          schemaPC.push({
            amount: amount,
            note: note,
            reason: reason,
            type: type,
          });
        }
      }
      if (reason === "Options") {
        if (
          new Date(transaction.date) > previous_trade &&
          new Date(transaction.date) < current_trade
        ) {
          schemaOptions.push({
            amount: amount,
            note: note,
            reason: reason,
            type: type,
          });
        }
      }
    } // end of transactions for loop

    let totalAmountIC = 0;

    schemaIC.forEach((transaction) => {
      noteIC = noteIC + " " + transaction.note;
      totalAmountIC = totalAmountIC + transaction.amount;
      if (transaction.type === "withdrawal") {
        balanceIC = balanceIC - totalAmountIC;
        startingBalanceIC = startingBalanceIC - totalAmountIC;
        newTransactionIC = `- ${totalAmountIC}`;
      } else if (transaction.type === "deposit") {
        balanceIC = balanceIC + totalAmountIC;
        startingBalanceIC = startingBalanceIC + totalAmountIC;
        newTransactionIC = `+ ${totalAmountIC}`;
      }
    });

    let totalAmountPC = 0;

    schemaPC.forEach((transaction) => {
      notePC = notePC + " " + transaction.note;
      totalAmountPC = totalAmountPC + transaction.amount;
      if (transaction.type === "withdrawal") {
        balancePC = balancePC - totalAmountPC;
        startingBalancePC = startingBalancePC - totalAmountPC;
        newTransactionPC = `- ${totalAmountPC}`;
      } else if (transaction.type === "deposit") {
        balancePC = balancePC + totalAmountPC;
        startingBalancePC = startingBalancePC + totalAmountPC;
        newTransactionPC = `+ ${totalAmountPC}`;
      }
    });

    let totalAmountOptions = 0;

    schemaOptions.forEach((transaction) => {
      noteOptions = noteOptions + " " + transaction.note;
      totalAmountOptions = totalAmountOptions + transaction.amount;
      if (transaction.type === "withdrawal") {
        balanceOptions = balanceOptions - totalAmountOptions;
        startingBalanceOptions = startingBalanceOptions - totalAmountOptions;
        newTransactionOptions = `- ${totalAmountOptions}`;
      } else if (transaction.type === "deposit") {
        balanceOptions = balanceOptions + totalAmountOptions;
        startingBalanceOptions = startingBalanceOptions + totalAmountOptions;
        newTransactionOptions = `+ ${totalAmountOptions}`;
      }
    });
  };
  const getTrades = (spefic) => {
    if (spefic) {
      // find spefic trade
      let singleTrade = auth.trades.find((trade) => trade._id.includes(spefic));

      if (singleTrade.equityType === "Personal Capital") {
        localStorage.setItem("equity-mode", "Personal Capital");
        auth.setEquityTypeMode("Personal Capital");
      } else if (singleTrade.equityType === "Investor Capital") {
        localStorage.setItem("equity-mode", "Investor Capital");
        auth.setEquityTypeMode("Investor Capital");
      } else if (singleTrade.equityType === "Personal & Investor Capital") {
        localStorage.setItem("equity-mode", "Personal & Investor Capital");
        auth.setEquityTypeMode("Personal & Investor Capital");
      } else if (singleTrade.equityType === "Options") {
        localStorage.setItem("equity-mode", "Options");
        auth.setEquityTypeMode("Options");
      }

      if (auth.backtestingMode) {
        getBacktests(singleTrade);
      } else {
        calculateFields([singleTrade], false);
      }
    } else {
      if (auth.backtestingMode) {
        showBacktestTrades({ testId: localStorage.getItem("backtest-active") });
      } else {
        calculateFields(auth.trades, false);
      }
    }
  };
  //-----------------------------------------------------------------------------------------//
  const checkStopLossMutiplyer = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { multiplier: pairObject?.multiplier };

    return schema;
  };
  const checkTypeOfPair = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { pipDistance: pairObject?.pipDistance };

    return schema;
  };
  const calculateOutcomeOfSlippage = (
    closePrice,
    openPrice,
    direction,
    pair
  ) => {
    const typeofPair = checkTypeOfPair(pair);

    if (isNaN(closePrice) || closePrice === "") {
      return "In Progress";
    } else {
      if (closePrice === openPrice) {
        return "Breakeven";
      }

      if (direction === "Long") {
        if (closePrice < openPrice) {
          const amountOfPipsLostBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice > openPrice) {
          const amountOfPipsWonBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      } else if (direction === "Short") {
        if (closePrice > openPrice) {
          const amountOfPipsLostBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice < openPrice) {
          const amountOfPipsWonBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      }
    }
  };
  const workOutWinLoseInPips = (
    outcome,
    direction,
    closePrice,
    openPrice,
    pair
  ) => {
    let pipsWonBy;
    let pipsLostBy;

    const typeofPair = checkTypeOfPair(pair);
    if (outcome === "Breakeven" || outcome === "In Progress") {
      pipsWonBy = 0;
      pipsLostBy = 0;
    }
    // handle longs
    if (direction === "Long" && outcome === "Win") {
      const amountOfPipsWonBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Long" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }
    // handle Shorts
    if (direction === "Short" && outcome === "Win") {
      const amountOfPipsWonBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Short" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }

    return { pipsLostBy: pipsLostBy, pipsWonBy: pipsWonBy };
  };
  // const calculateDollarPipRisk = (balance, stopLossPips) => {
  //   const dollarValueRiskPerTrade = (1 / 100) * balance;
  //   const perPipValue = dollarValueRiskPerTrade / stopLossPips;
  //   return {
  //     riskValue: parseFloat(dollarValueRiskPerTrade.toFixed(2)),
  //     valuePerPip: parseFloat(perPipValue.toFixed(2)),
  //   };
  // };
  const calculatePercentageReturn = (
    outcome,
    direction,
    pair,
    pipsWonBy,
    pipsLostBy,
    openPrice,
    risk,
    stopLossPips
  ) => {
    let percent = 0;
    let stop;
    let ESD;
    let DWP = pipsWonBy;
    let DLP = pipsLostBy;

    const multiplier = checkStopLossMutiplyer(pair).multiplier;
    const stopLoss = parseFloat(stopLossPips) / multiplier;
    const pipDistance = checkTypeOfPair(pair).pipDistance;

    if (outcome === "Breakeven" || outcome === "In Progress") {
      percent = 0;
    }

    if (direction === "Long" && outcome === "Win") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Long" && outcome === "Loss") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Loss") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Win") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }

    return percent;
  };
  const handleEditCell = async (cell, dontRun) => {
    const runFunction = async () => {
      const result = await DataFetcher("edit-trade", cell);
      if (result) {
        if (!dontRun) {
          auth.setTrades([]);
          auth.setAllTrades([]);
          const filteredArray = auth.trades.filter((x) => x._id !== result._id);
          filteredArray.push(result);
          filteredArray.sort((a, b) => {
            return moment(a.created).diff(b.created);
          });

          auth.setTrades(filteredArray);

          if (!auth.backtestingMode) {
            calculateFields(filteredArray, false);
          } else {
            calculateFields(filteredArray, true, result.testId);
          }
        }
      } else {
        throwMessage("error", "Error Try Again");
      }
    };

    const pc_array = [
      "closePricePC",
      "openPricePC",
      "stopLossPipsPC",
      "feePC",
      "profitLossDollarPersonal",
    ];
    const ic_array = [
      "closePriceIC",
      "openPriceIC",
      "stopLossPipsIC",
      "feeIC",
      "profitLossDollarInvestor",
    ];

    if (cell.id) {
      const entireCell = auth.trades.find((t) => t.id === cell.id);
      const mode = localStorage.getItem("equity-mode");
      if (mode === "Personal & Investor Capital") {
        if (entireCell.equityType === "Investor Capital") {
          if (!pc_array.includes(cell.field)) {
            runFunction();
          }
        } else if (entireCell.equityType === "Personal Capital") {
          if (!ic_array.includes(cell.field)) {
            runFunction();
          }
        } else if (entireCell.equityType === "Personal & Investor Capital") {
          runFunction();
        }
      } else {
        runFunction();
      }
    } else {
      throwMessage("error", "Error Try Again");
    }
  };
  //-----------------------------------------------------------------------------------------//

  const handleUpdatingRollingBalance = (equity, title, value, year) => {
    let array;
    switch (equity) {
      case "IC":
        array = localStorage.getItem("rollingBalancesIC");
        if (array) {
          const cleanedArray = JSON.parse(array);
          const object = {
            title: `${title} ${year ? year : ""}`,
            value: value,
          };
          const found = cleanedArray.find(
            (element) => element.title === `${title} ${year ? year : ""}`
          );
          if (found) {
            cleanedArray.forEach((element, index) => {
              if (element.title === `${title} ${year ? year : ""}`) {
                cleanedArray[index] = object;
              }
            });
            localStorage.setItem(
              "rollingBalancesIC",
              JSON.stringify(cleanedArray)
            );
          } else {
            cleanedArray.push(object);
            localStorage.setItem(
              "rollingBalancesIC",
              JSON.stringify(cleanedArray)
            );
          }
        } else {
          localStorage.setItem(
            "rollingBalancesIC",
            JSON.stringify([
              { title: `${title} ${year ? year : ""}`, value: value },
            ])
          );
        }
        break;
      case "PC":
        array = localStorage.getItem("rollingBalancesPC");
        if (array) {
          const cleanedArray = JSON.parse(array);
          const object = {
            title: `${title} ${year ? year : ""}`,
            value: value,
          };
          const found = cleanedArray.find(
            (element) => element.title === `${title} ${year ? year : ""}`
          );
          if (found) {
            cleanedArray.forEach((element, index) => {
              if (element.title === `${title} ${year ? year : ""}`) {
                cleanedArray[index] = object;
              }
            });
            localStorage.setItem(
              "rollingBalancesPC",
              JSON.stringify(cleanedArray)
            );
          } else {
            cleanedArray.push(object);
            localStorage.setItem(
              "rollingBalancesPC",
              JSON.stringify(cleanedArray)
            );
          }
        } else {
          localStorage.setItem(
            "rollingBalancesPC",
            JSON.stringify([
              { title: `${title} ${year ? year : ""}`, value: value },
            ])
          );
        }
        break;
      case "OPTIONS":
        array = localStorage.getItem("rollingBalancesOptions");
        if (array) {
          const cleanedArray = JSON.parse(array);
          const object = {
            title: `${title} ${year ? year : ""}`,
            value: value,
          };
          const found = cleanedArray.find(
            (element) => element.title === `${title} ${year ? year : ""}`
          );
          if (found) {
            cleanedArray.forEach((element, index) => {
              if (element.title === `${title} ${year ? year : ""}`) {
                cleanedArray[index] = object;
              }
            });
            localStorage.setItem(
              "rollingBalancesOptions",
              JSON.stringify(cleanedArray)
            );
          } else {
            cleanedArray.push(object);
            localStorage.setItem(
              "rollingBalancesOptions",
              JSON.stringify(cleanedArray)
            );
          }
        } else {
          localStorage.setItem(
            "rollingBalancesOptions",
            JSON.stringify([
              { title: `${title} ${year ? year : ""}`, value: value },
            ])
          );
        }
        break;
      default:
        break;
    }
  };

  const checkIfLastTradeOfYear = (equity, percent) => {
    const year = localStorage.getItem("year");
    let array;
    switch (equity) {
      case "IC":
        array = localStorage.getItem("rollingPercentsIC");
        if (array) {
          const cleanedArray = JSON.parse(array);
          const object = {
            title: `rollingPercentIC ${year ? year : ""}`,
            value: percent,
          };
          const found = cleanedArray.find(
            (element) => element.title === `rollingPercentIC ${year ? year : ""}`
          );
          if (found) {
            cleanedArray.forEach((element, index) => {
              if (element.title === `rollingPercentIC ${year ? year : ""}`) {
                cleanedArray[index] = object;
              }
            });
            localStorage.setItem(
              "rollingPercentsIC",
              JSON.stringify(cleanedArray)
            );
          } else {
            cleanedArray.push(object);
            localStorage.setItem(
              "rollingPercentsIC",
              JSON.stringify(cleanedArray)
            );
          }
        } else {
          localStorage.setItem(
            "rollingPercentsIC",
            JSON.stringify([
              { title: `rollingPercentIC ${year ? year : ""}`, value: percent },
            ])
          );
        }
        break;
      case "PC":
        array = localStorage.getItem("rollingPercentsPC");
        if (array) {
          const cleanedArray = JSON.parse(array);
          const object = {
            title: `rollingPercentPC ${year ? year : ""}`,
            value: percent,
          };
          const found = cleanedArray.find(
            (element) => element.title === `rollingPercentPC ${year ? year : ""}`
          );
          if (found) {
            cleanedArray.forEach((element, index) => {
              if (element.title === `rollingPercentPC ${year ? year : ""}`) {
                cleanedArray[index] = object;
              }
            });
            localStorage.setItem(
              "rollingPercentsPC",
              JSON.stringify(cleanedArray)
            );
          } else {
            cleanedArray.push(object);
            localStorage.setItem(
              "rollingPercentsPC",
              JSON.stringify(cleanedArray)
            );
          }
        } else {
          localStorage.setItem(
            "rollingPercentsPC",
            JSON.stringify([
              { title: `rollingPercentPC ${year ? year : ""}`, value: percent },
            ])
          );
        }
        break;
      case "OPTIONS":
        array = localStorage.getItem("rollingPercentsOptions");
        if (array) {
          const cleanedArray = JSON.parse(array);
          const object = {
            title: `rollingPercentOptions ${year ? year : ""}`,
            value: percent,
          };
          const found = cleanedArray.find(
            (element) => element.title === `rollingPercentOptions ${year ? year : ""}`
          );
          if (found) {
            cleanedArray.forEach((element, index) => {
              if (element.title === `rollingPercentOptions ${year ? year : ""}`) {
                cleanedArray[index] = object;
              }
            });
            localStorage.setItem(
              "rollingPercentsOptions",
              JSON.stringify(cleanedArray)
            );
          } else {
            cleanedArray.push(object);
            localStorage.setItem(
              "rollingPercentsOptions",
              JSON.stringify(cleanedArray)
            );
          }
        } else {
          localStorage.setItem(
            "rollingPercentsOptions",
            JSON.stringify([
              {
                title: `rollingPercentOptions ${year ? year : ""}`,
                value: percent,
              },
            ])
          );
        }
        break;

      default:
        break;
    }
  };
  const calculateFields = async (
    _allTrades,
    backtest,
    testId,
    rotar,
    equityCurve
  ) => {
    if (_allTrades === undefined) {
      return;
    }
    let trades = _allTrades;
    let profitLossDollarPersonal;
    let profitLossDollarInvestor;
    let profitLossPercentagePC;
    let profitLossPercentageIC;
    let rollingBalanceIC;
    let rollingBalancePC;
    let rollingReturnsOptions = 0;
    let allFeesPC = [];
    let allFeesIC = [];

    const mode = localStorage.getItem("equity-mode");

    let array_of_years_traded = auth.allYearsOfTrades;

    if (localStorage.getItem("year") !== "0") {
      const currentYearSet = moment(localStorage.getItem("year")).format("YY");

      trades = trades.filter((t, index) => {
        let dateInput = t.entryDate; // DD/MM/YY format

        let dateArray = dateInput.split("/");

        let newDate = dateArray[2];

        if (Number(newDate) === Number(currentYearSet)) {
          return t;
        } else {
          return false;
        }
      });
    }

    if (backtest) {
      trades = trades
        .filter((tests) => tests?.testId?.includes(testId))
        .sort((a, b) => {
          return moment(a.created).diff(b.created);
        });

      // trades = _allTrades.filter((trades) => trades?.equityType === "Backtest");

      if (trades.length === 0) {
        setRealisedPercentageBacktest(0);
        setRealisedReturnBacktest(0);
      }
    } else {
      trades = trades
        .filter((tests) => tests?.id?.includes("live-"))
        .sort((a, b) => {
          return moment(a.created).diff(b.created);
        });

      if (mode === "Personal Capital") {
        trades = trades.filter(
          (trades) => trades?.equityType === "Personal Capital"
        );
      } else if (mode === "Investor Capital") {
        trades = trades.filter(
          (trades) => trades?.equityType === "Investor Capital"
        );
      } else if (mode === "Options") {
        trades = trades.filter((trades) => trades?.equityType === "Options");
      } else if (mode === "Personal & Investor Capital") {
        trades = trades.filter((trades) => trades?.equityType !== "Options");
      }

      if (trades.length === 0) {
        setRealisedPercentage(0);
        setRealisedReturn(0);

        auth.setRealisedBalanceIC(0);
        auth.setRealisedBalancePC(0);
      }
    }

    if (trades.length === 0) {
      auth.setAllTrades([]);

      array_of_years_traded.forEach((year) => {
        if (mode === "Options") {
          if (localStorage.getItem("year") === "0") {
            handleUpdatingRollingBalance(
              "OPTIONS",
              "rollingBalanceOptions All",
              startingBalanceOptions
            );
          }
          if (localStorage.getItem("year") === year) {
            handleUpdatingRollingBalance(
              "OPTIONS",
              "rollingBalanceOptions",
              startingBalanceOptions,
              year
            );
          }
        }
        if (mode === "Investor Capital") {
          if (localStorage.getItem("year") === "0") {
            handleUpdatingRollingBalance(
              "IC",
              "rollingBalanceIC All",
              startingBalanceIC
            );
          }
          if (localStorage.getItem("year") === year) {
            handleUpdatingRollingBalance(
              "IC",
              "rollingBalanceIC",
              startingBalanceIC,
              year
            );
          }
        }
        if (mode === "Personal Capital") {
          if (localStorage.getItem("year") === "0") {
            handleUpdatingRollingBalance(
              "PC",
              "rollingBalancePC All",
              startingBalancePC
            );
          }
          if (localStorage.getItem("year") === year) {
            handleUpdatingRollingBalance(
              "PC",
              "rollingBalancePC",
              startingBalancePC,
              year
            );
          }
        }
      });
    }

    if (localStorage.getItem("year") !== array_of_years_traded[0]) {
      if (localStorage.getItem("year") !== "0") {
        balanceIC = JSON.parse(localStorage.getItem("rollingBalancesIC"))?.find(
          (element) =>
            element.title.includes(Number(localStorage.getItem("year")) - 1)
        )?.value;
        balancePC = JSON.parse(localStorage.getItem("rollingBalancesPC"))?.find(
          (element) =>
            element.title.includes(Number(localStorage.getItem("year")) - 1)
        )?.value;
        balanceOptions = JSON.parse(
          localStorage.getItem("rollingBalancesOptions")
        )?.find((element) =>
          element.title.includes(Number(localStorage.getItem("year")) - 1)
        )?.value;
      }
    }

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const capital = trade.equityType;

      transactionsCalulator(trades, trade, index);

      if (capital === "Investor Capital") {
        // const fee = parseFloat(trade.feeIC);
        // const fee_cleaned = Math.abs(fee);

        // const { riskValue, valuePerPip } = calculateDollarPipRisk(
        //   balance,
        //   trade.stopLossPipsIC
        // );

        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );

        if (localStorage.getItem("trade-to-view")) {
          if (outcomeOfSlippageIC === "In Progress") {
            auth.setInProgressMode(true);
            localStorage.setItem("inprogress-mode", true);
          } else {
            auth.setInProgressMode(false);
            localStorage.setItem("inprogress-mode", false);
          }
        }

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );
        allFeesIC.push(parseFloat(trade.feeIC));

        if (outcomeOfSlippageIC === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcomeOfSlippageIC === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (
          outcomeOfSlippageIC === "Breakeven" ||
          outcomeOfSlippageIC === "In Progress"
        ) {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        returnPercentTotalsIC.push(profitLossPercentageIC);
        returnDollarTotalsInvestor.push(profitLossDollarInvestor);

        //----------------------------------//

        let rollingPercentIC = returnPercentTotalsIC.reduce(function (a, b) {
          return a + b;
        });

        if (index === trades.length - 1) {
          checkIfLastTradeOfYear("IC", rollingPercentIC.toFixed(2));
        }

        //----------------------------------//

        balanceIC =
          balanceIC + Number(profitLossDollarInvestor) + Number(trade.feeIC);

        let returnDollarTotals_Investor_summed =
          returnDollarTotalsInvestor.reduce(function (a, b) {
            return a + b;
          });
        let allFees_summed_IC = allFeesIC.reduce(function (a, b) {
          return a + b;
        });

        returnDollarTotals_Investor_summed =
          returnDollarTotals_Investor_summed + allFees_summed_IC;

        rollingBalanceIC = balanceIC;

        if (index === trades.length - 1) {
          for (let index = 0; index < array_of_years_traded.length; index++) {
            const year = array_of_years_traded[index];
            if (localStorage.getItem("year") === "0") {
              handleUpdatingRollingBalance(
                "IC",
                "rollingBalanceIC All",
                rollingBalanceIC
              );
            }
            if (localStorage.getItem("year") === year) {
              handleUpdatingRollingBalance(
                "IC",
                "rollingBalanceIC",
                rollingBalanceIC,
                year
              );
            }
          }
        }

        const newTrade_virtual = {
          rollingPercentIC: rollingPercentIC,
          rollingBalanceIC: rollingBalanceIC.toFixed(2),
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          // riskValue: parseFloat(riskValue.toFixed(2)),
          // valuePerPip: parseFloat(valuePerPip.toFixed(2)),
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: 0,
          profitLossPercentagePC: 0,
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          // rr: riskToRewardDecimal.toFixed(2),
          outcomePC: "",
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: 0,
          closePricePC: 0,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: 0,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          dxyHourlyBefore: trade.dxyHourlyBefore,
          dxyHourlyAfter: trade.dxyHourlyAfter,
          dxyDailyBefore: trade.dxyDailyBefore,
          dxyDailyAfter: trade.dxyDailyAfter,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          newTransactionIC: newTransactionIC,
          transanctionNotesIC: noteIC,
          returnTotalsPersonal: 0,
          returnTotalsInvestor: returnDollarTotals_Investor_summed.toFixed(2),
          created: trade.created,
          equityType: trade.equityType,
        };

        virtualFields.push(newTrade_virtual);

        virtualFields = virtualFields.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        if (!rotar && !equityCurve) {
          if (localStorage.getItem("inprogress-mode") === "true") {
            auth.setAllTrades(
              virtualFields.filter((trade) =>
                trade.outcomeIC.includes("In Progress")
              )
            );
          } else {
            auth.setAllTrades(
              virtualFields.filter(
                (trade) => !trade.outcomeIC?.includes("In Progress")
              )
            );
          }
        }

        //---------------------------------------------//
      } else if (capital === "Personal Capital" || capital === "Backtest") {
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippagePC,
          // outcome,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );

        if (localStorage.getItem("trade-to-view")) {
          if (outcomeOfSlippagePC === "In Progress") {
            auth.setInProgressMode(true);
            localStorage.setItem("inprogress-mode", true);
          } else {
            auth.setInProgressMode(false);
            localStorage.setItem("inprogress-mode", false);
          }
        }

        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );
        allFeesPC.push(parseFloat(trade.feePC));
        if (outcomeOfSlippagePC === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcomeOfSlippagePC === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (
          outcomeOfSlippagePC === "Breakeven" ||
          outcomeOfSlippagePC === "In Progress"
        ) {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        returnPercentTotalsPC.push(profitLossPercentagePC);
        returnDollarTotalsPersonal.push(profitLossDollarPersonal);

        balancePC =
          balancePC + Number(profitLossDollarPersonal) + Number(trade.feePC);

        let returnDollarTotals_Personal_summed =
          returnDollarTotalsPersonal.reduce(function (a, b) {
            return a + b;
          });

        let allFees_summed_PC = allFeesPC.reduce(function (a, b) {
          return a + b;
        });

        returnDollarTotals_Personal_summed =
          returnDollarTotals_Personal_summed + allFees_summed_PC;

        rollingBalancePC = balancePC;

        //----------------------------------//

        let rollingPercentPC = returnPercentTotalsPC.reduce(function (a, b) {
          return a + b;
        });

        if (index === trades.length - 1) {
          checkIfLastTradeOfYear("PC", rollingPercentPC.toFixed(2));
        }

        //----------------------------------//

        if (index === trades.length - 1) {
          for (let index = 0; index < array_of_years_traded.length; index++) {
            const year = array_of_years_traded[index];
            if (localStorage.getItem("year") === "0") {
              handleUpdatingRollingBalance(
                "PC",
                "rollingBalancePC All",
                rollingBalancePC
              );
            }
            if (localStorage.getItem("year") === year) {
              handleUpdatingRollingBalance(
                "PC",
                "rollingBalancePC",
                rollingBalancePC,
                year
              );
            }
          }
        }

        const newTrade_virtual = {
          rollingPercentPC: rollingPercentPC,
          rollingBalancePC: rollingBalancePC.toFixed(2),
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: 0,
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: "",
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          dxyHourlyBefore: trade.dxyHourlyBefore,
          dxyHourlyAfter: trade.dxyHourlyAfter,
          dxyDailyBefore: trade.dxyDailyBefore,
          dxyDailyAfter: trade.dxyDailyAfter,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed.toFixed(2),
          returnTotalsInvestor: 0,
          created: trade.created,
          equityType: trade.equityType,
          newTransactionPC: newTransactionPC,
          transanctionNotesPC: notePC,
        };

        virtualFields.push(newTrade_virtual);

        virtualFields = virtualFields.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        if (!rotar && !equityCurve) {
          if (localStorage.getItem("inprogress-mode") === "true") {
            auth.setAllTrades(
              virtualFields.filter((trade) =>
                trade.outcomePC.includes("In Progress")
              )
            );
          } else {
            auth.setAllTrades(
              virtualFields.filter(
                (trade) => !trade.outcomePC?.includes("In Progress")
              )
            );
          }
        }

        //---------------------------------------------//
      } else if (capital === "Personal & Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );

        if (localStorage.getItem("trade-to-view")) {
          if (
            outcomeOfSlippageIC === "In Progress" ||
            outcomeOfSlippagePC === "In Progress"
          ) {
            auth.setInProgressMode(true);
            localStorage.setItem("inprogress-mode", true);
          } else {
            auth.setInProgressMode(false);
            localStorage.setItem("inprogress-mode", false);
          }
        }

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippagePC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );

        allFeesIC.push(parseFloat(trade.feeIC));
        allFeesPC.push(parseFloat(trade.feePC));

        if (outcomeOfSlippageIC === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcomeOfSlippageIC === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (
          outcomeOfSlippageIC === "Breakeven" ||
          outcomeOfSlippageIC === "In Progress"
        ) {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        if (outcomeOfSlippagePC === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcomeOfSlippagePC === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (
          outcomeOfSlippagePC === "Breakeven" ||
          outcomeOfSlippagePC === "In Progress"
        ) {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        returnPercentTotalsIC.push(profitLossPercentageIC);
        returnDollarTotalsInvestor.push(profitLossDollarInvestor);

        returnPercentTotalsPC.push(profitLossPercentagePC);
        returnDollarTotalsPersonal.push(profitLossDollarPersonal);

        balanceIC =
          balanceIC + Number(profitLossDollarInvestor) + Number(trade.feeIC);

        balancePC =
          balancePC + Number(profitLossDollarPersonal) + Number(trade.feePC);

        let returnDollarTotals_Investor_summed =
          returnDollarTotalsInvestor.reduce(function (a, b) {
            return a + b;
          });

        let returnDollarTotals_Personal_summed =
          returnDollarTotalsPersonal.reduce(function (a, b) {
            return a + b;
          });

        let allFees_summed_IC = allFeesIC.reduce(function (a, b) {
          return a + b;
        });

        let allFees_summed_PC = allFeesPC.reduce(function (a, b) {
          return a + b;
        });

        returnDollarTotals_Investor_summed =
          returnDollarTotals_Investor_summed + allFees_summed_IC;

        returnDollarTotals_Personal_summed =
          returnDollarTotals_Personal_summed + allFees_summed_PC;

        rollingBalanceIC = balanceIC;
        rollingBalancePC = balancePC;

        const newTrade_virtual = {
          rollingBalancePC: rollingBalancePC.toFixed(2),
          rollingBalanceIC: rollingBalanceIC.toFixed(2),
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          dxyHourlyBefore: trade.dxyHourlyBefore,
          dxyHourlyAfter: trade.dxyHourlyAfter,
          dxyDailyBefore: trade.dxyDailyBefore,
          dxyDailyAfter: trade.dxyDailyAfter,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed.toFixed(2),
          returnTotalsInvestor: returnDollarTotals_Investor_summed.toFixed(2),
          created: trade.created,
          equityType: trade.equityType,
          newTransactionIC: newTransactionIC,
          transanctionNotesIC: noteIC,
          newTransactionPC: newTransactionPC,
          transanctionNotesPC: notePC,
        };

        virtualFields.push(newTrade_virtual);

        virtualFields = virtualFields.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        if (!rotar && !equityCurve) {
          if (localStorage.getItem("inprogress-mode") === "true") {
            auth.setAllTrades(
              virtualFields.filter(
                (trade) =>
                  trade.outcomeIC.includes("In Progress") ||
                  trade.outcomePC.includes("In Progress")
              )
            );
          } else {
            auth.setAllTrades(
              virtualFields.filter(
                (trade) =>
                  !trade.outcomeIC?.includes("In Progress") ||
                  !trade.outcomePC?.includes("In Progress")
              )
            );
          }
        }
      } else if (capital === "Options") {
        const dollarReturn = Number(trade.profitLossDollarOptions);
        const premium = Number(trade.premium);
        let returnPercent = (dollarReturn / balanceOptions) * 100;
        const riskPercent = (Number(trade.premium) / balanceOptions) * 100;
        balanceOptions = balanceOptions - Number(trade.premium) + dollarReturn;

        rollingReturnsOptions =
          rollingReturnsOptions + (dollarReturn - premium);

        returnPercent = returnPercent - riskPercent;

        returnPercentTotalsOptions.push(returnPercent);

        //----------------------------------//

        let rollingPercentOptions = returnPercentTotalsOptions.reduce(function (
          a,
          b
        ) {
          return a + b;
        });

        if (index === trades.length - 1) {
          checkIfLastTradeOfYear("OPTIONS", rollingPercentOptions.toFixed(2));
        }

        //----------------------------------//

        const workOutOutcome = () => {
          if (trade.exitDate) {
            return dollarReturn > premium
              ? "Win"
              : dollarReturn === premium
              ? "Breakeven"
              : dollarReturn < premium
              ? "Loss"
              : "";
          } else {
            return "In Progress";
          }
        };

        if (index === trades.length - 1) {
          for (let index = 0; index < array_of_years_traded.length; index++) {
            const year = array_of_years_traded[index];
            if (localStorage.getItem("year") === "0") {
              handleUpdatingRollingBalance(
                "OPTIONS",
                "rollingBalanceOptions All",
                balanceOptions
              );
            }
            if (localStorage.getItem("year") === year) {
              handleUpdatingRollingBalance(
                "OPTIONS",
                "rollingBalanceOptions",
                balanceOptions,
                year
              );
            }
          }
        }

        if (localStorage.getItem("equityCurve") === "false") {
          if (
            trade.profitLossPercentageOptions !== returnPercent.toString() ||
            trade.riskOptions !== riskPercent.toString()
          ) {
            handleEditCell(
              {
                id: trade.id,
                value: returnPercent,
                field: "profitLossPercentageOptions",
              },
              true
            );
            setTimeout(() => {
              handleEditCell(
                {
                  id: trade.id,
                  value: riskPercent,
                  field: "riskOptions",
                },
                true
              );
            }, 2000);
          }
        }

        const newTrade_virtual = {
          rollingPercentOptions: rollingPercentOptions,
          outcomeOptions: workOutOutcome(),
          rollingBalanceOptions: balanceOptions.toFixed(2),
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossPercentageOptions: returnPercent.toFixed(2),
          profitLossDollarOptions: trade.profitLossDollarOptions,
          returnTotalsOptions: rollingReturnsOptions.toFixed(2),
          pair: trade.pair,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          expiry: trade.expiry,
          // feeOptions: trade.feeOptions,
          riskOptions: riskPercent.toFixed(2),
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          pattern: trade.pattern,
          premium: trade.premium,
          optionType: trade.optionType,
          strike: trade.strike,
          quantity: trade.quantity,
          openPriceOptions: trade.openPriceOptions,
          closePriceOptions: trade.closePriceOptions,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          dxyHourlyBefore: trade.dxyHourlyBefore,
          dxyHourlyAfter: trade.dxyHourlyAfter,
          dxyDailyBefore: trade.dxyDailyBefore,
          dxyDailyAfter: trade.dxyDailyAfter,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,

          returnTotalsPersonal: 0,
          returnTotalsInvestor: 0,
          created: trade.created,
          equityType: trade.equityType,
          newTransactionOptions: newTransactionOptions,
          transanctionNotesOptions: noteOptions,
        };

        virtualFields.push(newTrade_virtual);

        virtualFields = virtualFields.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        if (!rotar && !equityCurve) {
          if (localStorage.getItem("inprogress-mode") === "true") {
            auth.setAllTrades(
              virtualFields.filter((trade) =>
                trade.outcomeOptions.includes("In Progress")
              )
            );
          } else {
            auth.setAllTrades(
              virtualFields.filter(
                (trade) => !trade?.outcomeOptions.includes("In Progress")
              )
            );
          }
        }

        //---------------------------------------------//
      }
      //---------------------------------------------//
      newTransactionPC = undefined;
      notePC = "";
      newTransactionIC = undefined;
      noteIC = "";
    } // end of Alltrades for loop

    auth.setRealisedBalancePC(balancePC?.toFixed(2));
    localStorage.setItem("balancePC", balancePC?.toFixed(2));
    auth.setRealisedBalanceIC(balanceIC?.toFixed(2));
    localStorage.setItem("balanceIC", balanceIC?.toFixed(2));
    auth.setRealisedBalanceOptions(balanceOptions?.toFixed(2));
    localStorage.setItem("balanceOptions", balanceOptions?.toFixed(2));

    const runRotaterCals = () => {
      const rotater = rotar ? rotar : localStorage.getItem("rotater");
      const mode = localStorage.getItem("equity-mode");
      const all = mode === "Personal & Investor Capital";
      const personal = mode === "Personal Capital";
      const investor = mode === "Investor Capital";
      const options = mode === "Options";

      let trades = virtualFields;

      const mapFromColors = new Map(trades.map((c) => [c.id, c]));

      trades = [...mapFromColors.values()];

      setRealisedReturn(0);
      setRealisedPercentage(0);
      setRealisedReturnBacktest(0);
      setRealisedPercentageBacktest(0);

      const year = Number(localStorage.getItem("year"));

      const handleAction = (
        percent,
        pound,
        profitLossPercentagePC,
        profitLossDollarPC,
        profitLossPercentageIC,
        profitLossDollarIC,
        profitLossPercentageOptions,
        profitLossDollarOptions
      ) => {
        if (all) {
          percent.push(parseFloat(profitLossPercentagePC));
          pound.push(parseFloat(profitLossDollarPC));
          percent.push(parseFloat(profitLossPercentageIC));
          pound.push(parseFloat(profitLossDollarIC));
        } else if (personal) {
          percent.push(parseFloat(profitLossPercentagePC));
          pound.push(parseFloat(profitLossDollarPC));
        } else if (investor) {
          percent.push(parseFloat(profitLossPercentageIC));
          pound.push(parseFloat(profitLossDollarIC));
        } else if (options) {
          percent.push(parseFloat(profitLossPercentageOptions));
          pound.push(parseFloat(profitLossDollarOptions));
        }
      };
      const last90Days = () => {
        const percent = [0];
        const pound = [0];

        trades.forEach((t) => {
          if (
            moment().diff(
              new Date(moment(t.exitDate + " " + t.exitTime, "DD/MM/YY HH:mm")),
              "days"
            ) <= 90
          ) {
            handleAction(
              percent,
              pound,
              t.profitLossPercentagePC,
              t.profitLossDollarPC,
              t.profitLossPercentageIC,
              t.profitLossDollarIC,
              t.profitLossPercentageOptions,
              t.profitLossDollarOptions
            );
          }
        });
        const schema = {
          percent: percent.reduce(function (a, b) {
            return a + b;
          }),
          pound: pound.reduce(function (a, b) {
            return a + b;
          }),
        };

        setRealisedPercentage(schema.percent.toFixed(2));
        setRealisedReturn(schema.pound.toFixed(2));
        setRealisedPercentageBacktest(schema.percent.toFixed(2));
        setRealisedReturnBacktest(schema.pound.toFixed(2));
      };
      const last30Days = () => {
        const percent = [0];
        const pound = [0];
        trades.forEach((t) => {
          if (
            moment().diff(
              new Date(moment(t.exitDate + " " + t.exitTime, "DD/MM/YY HH:mm")),
              "days"
            ) <= 30
          ) {
            handleAction(
              percent,
              pound,
              t.profitLossPercentagePC,
              t.profitLossDollarPC,
              t.profitLossPercentageIC,
              t.profitLossDollarIC,
              t.profitLossPercentageOptions,
              t.profitLossDollarOptions
            );
          }
        });

        const schema = {
          percent: percent.reduce(function (a, b) {
            return a + b;
          }),
          pound: pound.reduce(function (a, b) {
            return a + b;
          }),
        };

        setRealisedPercentage(schema.percent.toFixed(2));
        setRealisedReturn(schema.pound.toFixed(2));
        setRealisedPercentageBacktest(schema.percent.toFixed(2));
        setRealisedReturnBacktest(schema.pound.toFixed(2));
      };
      const last7Days = () => {
        const percent = [0];
        const pound = [0];
        trades.forEach((t) => {
          if (
            moment().diff(
              new Date(moment(t.exitDate + " " + t.exitTime, "DD/MM/YY HH:mm")),
              "days"
            ) <= 7
          ) {
            handleAction(
              percent,
              pound,
              t.profitLossPercentagePC,
              t.profitLossDollarPC,
              t.profitLossPercentageIC,
              t.profitLossDollarIC,
              t.profitLossPercentageOptions,
              t.profitLossDollarOptions
            );
          }
        });
        const schema = {
          percent: percent.reduce(function (a, b) {
            return a + b;
          }),
          pound: pound.reduce(function (a, b) {
            return a + b;
          }),
        };

        setRealisedPercentage(schema.percent.toFixed(2));
        setRealisedReturn(schema.pound.toFixed(2));
        setRealisedPercentageBacktest(schema.percent.toFixed(2));
        setRealisedReturnBacktest(schema.pound.toFixed(2));
      };
      const last24Hours = () => {
        const percent = [0];
        const pound = [0];
        trades.forEach((t) => {
          if (
            moment().diff(
              new Date(moment(t.exitDate + " " + t.exitTime, "DD/MM/YY HH:mm")),
              "hours"
            ) <= 24
          ) {
            handleAction(
              percent,
              pound,
              t.profitLossPercentagePC,
              t.profitLossDollarPC,
              t.profitLossPercentageIC,
              t.profitLossDollarIC,
              t.profitLossPercentageOptions,
              t.profitLossDollarOptions
            );
          }
        });
        const schema = {
          percent: percent.reduce(function (a, b) {
            return a + b;
          }),
          pound: pound.reduce(function (a, b) {
            return a + b;
          }),
        };

        setRealisedPercentage(schema.percent.toFixed(2));
        setRealisedReturn(schema.pound.toFixed(2));
        setRealisedPercentageBacktest(schema.percent.toFixed(2));
        setRealisedReturnBacktest(schema.pound.toFixed(2));
      };
      const thisYear = () => {
        const percent = [0];
        const pound = [0];

        trades.forEach((t) => {
          if (year !== 0) {
            if (
              moment(year, "YYYY").isSame(
                moment(t.exitDate, "DD/MM/YY"),
                "year"
              )
            ) {
              handleAction(
                percent,
                pound,
                t.profitLossPercentagePC,
                t.profitLossDollarPC,
                t.profitLossPercentageIC,
                t.profitLossDollarIC,
                t.profitLossPercentageOptions,
                t.profitLossDollarOptions
              );
            }
          } else {
            handleAction(
              percent,
              pound,
              t.profitLossPercentagePC,
              t.profitLossDollarPC,
              t.profitLossPercentageIC,
              t.profitLossDollarIC,
              t.profitLossPercentageOptions,
              t.profitLossDollarOptions
            );
          }
        });
        const schema = {
          percent: percent.reduce(function (a, b) {
            return a + b;
          }),
          pound: pound.reduce(function (a, b) {
            return a + b;
          }),
        };

        setRealisedPercentage(schema.percent.toFixed(2));
        setRealisedReturn(schema.pound.toFixed(2));
        setRealisedPercentageBacktest(schema.percent.toFixed(2));
        setRealisedReturnBacktest(schema.pound.toFixed(2));
      };
      const allTime = () => {
        const percent = [0];
        const pound = [0];
        trades.forEach((t) => {
          handleAction(
            percent,
            pound,
            t.profitLossPercentagePC,
            t.profitLossDollarPC,
            t.profitLossPercentageIC,
            t.profitLossDollarIC,
            t.profitLossPercentageOptions,
            t.profitLossDollarOptions
          );
        });
        const schema = {
          percent: percent.reduce(function (a, b) {
            return a + b;
          }),
          pound: pound.reduce(function (a, b) {
            return a + b;
          }),
        };

        setRealisedPercentage(schema.percent.toFixed(2));
        setRealisedReturn(schema.pound.toFixed(2));
        setRealisedPercentageBacktest(schema.percent.toFixed(2));
        setRealisedReturnBacktest(schema.pound.toFixed(2));
      };

      switch (rotater) {
        case "3 Months":
          last90Days();
          break;
        case "Monthly":
          last30Days();
          break;
        case "Weekly":
          last7Days();
          break;
        case "24 Hours":
          last24Hours();
          break;
        case "All":
          allTime();
          break;
        case "Start Of Year":
          thisYear();
          break;
        default:
          break;
      }
    };
    runRotaterCals();

    return virtualFields;
  };
  //-----------------------------------------------------------------------------------------//
  const idTradeGenerator = (type) => {
    if (type === "backtest") {
      const id = "backtest-" + Math.random().toString(16).slice(2);
      return id;
    } else if (type === "live") {
      const id = "live-" + Math.random().toString(16).slice(2);
      return id;
    } else if (type === "journal") {
      const id = "journal-" + Math.random().toString(16).slice(2);
      return id;
    }
  };
  const handleSreenMode = (event) => {
    localStorage.setItem("view-mode", event.target.value);
    auth.setViewMode(event.target.value);
  };
  const handleUpdateBalance = async (balance) => {
    const result = await DataFetcher("update-backtest-starting-balance", {
      newBalance: balance,
    });
    if (result) {
      throwMessage("success", "Success");
    }
  };
  const showBacktestTrades = (test) => {
    // localStorage.removeItem("trade-to-view")
    localStorage.setItem("backtest-active", test.testId);
    calculateFields(auth.trades, true, test.testId);
    setShowSpecficTest(test.testId);
  };
  const handleRemove = async () => {
    if (!formSubmitted);
    setFormSubmitted(true);
    setRemove(false);
    setToRemove([]);

    const result = await DataFetcher("remove-trade", {
      id: toRemove,
      backtestingMode: auth.backtestingMode,
    });
    if (result.deletedCount === toRemove.length) {
      if (!auth.backtestingMode) {
        const newArray = await auth.trades
          .filter((obj) => !toRemove.some((obj2) => obj._id === obj2._id))
          .sort((a, b) => {
            return moment(a.created).diff(b.created);
          });
        const newAllTradesArray = await auth.allTrades.filter(
          (obj) => !toRemove.some((obj2) => obj._id === obj2._id)
        );
        auth.setAllTrades(newAllTradesArray);
        auth.setTrades(newArray);
        calculateFields(newArray, false);
        throwMessage("success", "Deleted");
        if (newArray.length === 0) {
          setRealisedPercentage(0);
          setRealisedReturn(0);
        }
      } else {
        const newAllTradesArray = auth.allTrades.filter(
          (obj) => !toRemove.some((obj2) => obj._id === obj2._id)
        );

        auth.setAllTrades(newAllTradesArray);

        const newArray = auth.trades.filter(
          (obj) => !toRemove.some((obj2) => obj._id === obj2._id)
        );
        auth.setTrades(newArray);

        if (newAllTradesArray.length > 0) {
          calculateFields(newAllTradesArray, true, toRemove[0].testId);
        } else {
          setRealisedPercentageBacktest(0);
          setRealisedReturnBacktest(0);
        }
      }
      setFormSubmitted(false);
    } else {
      setFormSubmitted(false);
      throwMessage("error", "Something went wrong");
    }
  };
  const handleBacktestingMode = (event, width) => {
    if (width > 500) {
      auth.handleBacktestingMode(event.target.checked);
      localStorage.setItem("backtest-mode", event.target.checked);
      localStorage.removeItem("backtest-active");
      setRealisedReturnBacktest(0);
      setRealisedPercentageBacktest(0);
      if (event.target.checked) {
        setShowSpecficTest(localStorage.getItem("backtest-active"));
        calculateFields(
          auth.trades,
          true,
          localStorage.getItem("backtest-active")
        );
      } else {
        calculateFields(auth.trades, false);
      }
    } else {
      throwMessage("warning", "Desktop Only Feature");
    }
  };
  const handleArchivingTest = async (test) => {
    const schema = {
      id: test._id,
      field: "archived",
      value: "true",
    };
    if (!formSubmitted);
    setFormSubmitted(true);
    const result = await DataFetcher("edit-backtest", schema);
    if (result) {
      const filteredArray = allbackTestSchemas.filter(
        (x) => x._id !== result._id
      );

      auth.setAllTrades([]);
      setShowSpecficTest("");
      localStorage.removeItem("backtest-active");
      filteredArray.push(result);
      setAllbackTestSchemas(filteredArray);
      auth.setBacktests(filteredArray);
      setSelectedArchivedTests("");
      setSelectedArchivedTestsBoolean(false);
      setFormSubmitted(false);
    } else {
      setFormSubmitted(false);
      throwMessage("error", "Something went wrong");
    }
  };
  const getBacktests = async (trade) => {
    const testId = trade.testId;
    localStorage.setItem("backtest-active", testId);
    setShowSpecficTest(testId);
    setAllbackTestSchemas(auth.backtests);
    calculateFields([trade], true, testId);
  };
  const handleDeleteTest = async (test) => {
    if (!formSubmitted);
    setFormSubmitted(true);
    const test_id = JSON.parse(test)[1];
    const toDelete = auth.trades
      .filter(
        (trades) =>
          trades.id.includes("backtest-") && trades.testId.includes(test_id)
      )
      .map((t) => {
        return { _id: t._id };
      });

    const toDeleteJournals = auth.trades
      .filter(
        (trades) =>
          trades.id.includes("backtest-") && trades.testId.includes(test_id)
      )
      .map((t) => {
        return { journalID: t.journalID };
      });

    const schema = {
      id: JSON.parse(test)[0],
      testIds: toDelete,
      journalID: toDeleteJournals,
      backtestingMode: auth.backtestingMode,
    };

    const result = await DataFetcher("remove-backtest", schema);

    if (result) {
      const filteredArray = auth.backtests.filter(
        (x) => x._id !== result.backtestId
      );
      auth.setBacktests(filteredArray);
      setAllbackTestSchemas(filteredArray);
      setSelectedArchivedTests("");
      setSelectedArchivedTestsBoolean(false);
      throwMessage("success", "Backtest Removed");
      setFormSubmitted(false);
    } else {
      setFormSubmitted(false);
      throwMessage("error", "Something went wrong");
    }
  };
  const handleUnArchivedTest = async (test) => {
    if (
      allbackTestSchemas?.filter((tests) => tests.archived?.includes("false"))
        .length <= 2
    ) {
      const schema = {
        id: JSON.parse(test)[0],
        field: "archived",
        value: "false",
      };
      if (!formSubmitted);
      setFormSubmitted(true);
      const result = await DataFetcher("edit-backtest", schema);

      if (result) {
        const filteredArray = allbackTestSchemas.filter(
          (x) => x._id !== result._id
        );
        filteredArray.push(result);
        setAllbackTestSchemas(filteredArray);
        auth.setBacktests(filteredArray);
        setSelectedArchivedTests("");
        setSelectedArchivedTestsBoolean(false);
        setFormSubmitted(false);
      } else {
        setFormSubmitted(false);
        throwMessage("error", "Something went wrong");
      }
    } else {
      throwMessage("warning", "Limit Reached");
    }
  };
  const handleInProgressMode = (event) => {
    localStorage.removeItem("trade-to-view");
    auth.setInProgressMode(event.target.checked);
    localStorage.setItem("inprogress-mode", event.target.checked);
    if (auth.backtestingMode) {
      calculateFields(
        auth.trades,
        true,
        localStorage.getItem("backtest-active")
      );
    } else {
      calculateFields(auth.trades, false);
    }
  };
  const handleSimpleView = (event) => {
    if (!event.target.checked) {
      auth.setViewMode("simple-view");
      localStorage.setItem("view-mode", "simple-view");
    } else {
      auth.setViewMode("all-view");
      localStorage.setItem("view-mode", "all-view");
    }
  };
  const runDurationCal = (params) => {
    let entryDate = params.row.entryDate.split(/\//);
    let exitDate = params.row.exitDate.split(/\//);

    entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
    exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

    const date = {
      entryDate: entryDate,
      entryTime: params.row.entryTime,
      exitDate: exitDate,
      exitTime: params.row.exitTime,
    };

    const startDate = moment(new Date(date.entryDate + " " + date.entryTime));

    const endDate = moment(new Date(date.exitDate + " " + date.exitTime));

    let hours = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .asHours();

    let days = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .asDays()
      .toFixed(1);

    if (!isNaN(hours)) {
      return (
        <p>
          {hours >= 24 ? days : hours.toFixed(1)} {hours >= 24 ? "Days" : "Hrs"}
        </p>
      );
    } else {
      return <p>In Progress</p>;
    }
  };
  const handleRemoveButton = (selectedRowData) => {
    if (selectedRowData.length > 0) {
      setRemove(true);
    } else {
      setRemove(false);
    }
  };

  //-----------------------------------------------------------------------------------------//

  return {
    calculateFields,
    handleSimpleView,
    handleSreenMode,
    allbackTestSchemas,
    showBacktestTrades,
    remove,
    handleRemove,
    handleOpen,
    realisedPercentage,
    realisedReturn,
    handleBacktestingMode,
    selectedArchivedTests,
    setSelectedArchivedTests,
    handleOpenBacktest,
    handleArchivingTest,
    handleDeleteTest,
    setSelectedArchivedTestsBoolean,
    selectedArchivedTestsBoolean,
    handleUnArchivedTest,
    showSpecficTest,
    realisedPercentageBacktest,
    realisedReturnBacktest,
    handleUpdateBalance,
    handleOpenNewDeposit,
    handleInProgressMode,
    handleEditCell,
    setToRemove,
    setOpenJournal,
    idTradeGenerator,
    runDurationCal,
    handleRemoveButton,
    handleClose,
    open,
    handleCloseNewDeposit,
    openNewDeposit,
    handleCloseBacktest,
    openNewTest,
    handleCloseJournal,
    openJournal,
    handleOpenJournal,
    setAllbackTestSchemas,
    formSubmitted,
    handleCloseCal,
    openCal,
    handleOpenCal,
    calculateOutcomeOfSlippage,
    equityCurve,
    setEquityCurve,
  };
};

export default Logic;

// const calculateDollarPipRisk = (balance, stopLossPips, pair, risk) => {
//   const multiplier = checkStopLossMutiplyer(pair).multiplier;
//   const stopLoss = parseFloat(stopLossPips) / multiplier;
//   const dollarValueRiskPerTrade = (parseFloat(risk) / 100) * balance;
//   const perPipValue = dollarValueRiskPerTrade / stopLoss;
//   return {
//     riskValue: parseFloat(dollarValueRiskPerTrade),
//     valuePerPip: parseFloat(perPipValue),
//   };
// };

// const riskRewardRatio = (entry, target, direction, stopLossPips, pair) => {
//   const multiplier = checkStopLossMutiplyer(pair).multiplier;
//   const stopLoss = parseFloat(stopLossPips) / multiplier;
//   let stop;
//   const pipDistance = checkTypeOfPair(pair).pipDistance;

//   if (direction === "Long") {
//     stop = parseFloat(entry) - stopLoss * pipDistance;
//     const ratio = (entry - stop) / (parseFloat(target) - entry);

//     if (!isFinite(ratio)) {
//       return 0;
//     } else {
//       return ratio;
//     }
//   } else if (direction === "Short") {
//     stop = parseFloat(entry) + stopLoss * pipDistance;
//     const ratio = (stop - entry) / (entry - parseFloat(target));
//     if (!isFinite(ratio)) {
//       return 0;
//     } else {
//       return ratio;
//     }
//   }
// };
