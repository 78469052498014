import React from "react";
import moment from "moment";
import useWindowDimensions from "../../utils/width";

const PercentReturns = ({ trades, runSpeficCal, theme }) => {
  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        padding: "0.7em",
        borderRadius: "10px",
        width: "100%",
        maxWidth: "770px",
      }}
    >
      <table
        className={
          theme[0] === "dark" ? "tableHoverEffectDark" : "tableHoverEffectLight"
        }
        style={{
          tableLayout: "fixed",
          borderCollapse: "collapse",
          width: "100%",
          fontSize: width <= 700 ? "13px" : "18px",
        }}
      >
        <caption
          style={{
            marginBottom: "10px",
            fontSize: width <= 700 ? "16px" : "18px",
            fontWeight: "400",
            borderBottom: `1px solid ${
              theme[0] === "dark" ? "#FCFCFF" : "black"
            }`,
            paddingBottom: "10px",
          }}
        >
          Returns
        </caption>
        <thead
          className="tableHoverEffectOff"
          style={{ fontSize: width <= 700 ? "13px" : "16px", height: "50px" }}
        >
          <tr
            style={{
              position: "sticky",
              top: "0",
              backdropFilter: "blur(10px)",
            }}
          >
            <th style={{ width: "30px", textAlign: "center" }}></th>
            <th style={{ fontWeight: "400" }}>Month</th>
            <th style={{ fontWeight: "400" }}>Monthly %</th>
            <th style={{ fontWeight: "400", color: "#0079cf" }}>Longs %</th>
            <th style={{ fontWeight: "400", color: "#db0671" }}>Shorts %</th>
            <th style={{ fontWeight: "400" }}>Total %</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              textAlign: "center",
            }}
          >
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Jan
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 0
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 0 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 0 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}

            <td></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Q1
            </th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Feb
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 1
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 1 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}

            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 1 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  moment(t.entryDate, "DD/MM/YY").month() === 0 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 1 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 2
              )
            )}
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Mar
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 2
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 2 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 2 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}

            <td></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            >
              Apr
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 3
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 3 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 3 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              ),
              true
            )}
            <td
              style={{
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            ></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Q2
            </th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              May
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 4
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 4 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}

            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 4 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  moment(t.entryDate, "DD/MM/YY").month() === 3 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 4 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 5
              )
            )}
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              June
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 5
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 5 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 5 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}

            <td></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            >
              July
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 6
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 6 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 6 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              ),
              true
            )}
            <td
              style={{
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            ></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Q3
            </th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Aug
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 7
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 7 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 7 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  moment(t.entryDate, "DD/MM/YY").month() === 6 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 7 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 8
              )
            )}
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Sept
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 8
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 8 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 8 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}
            <td></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            >
              Oct
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 9
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 9 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 9 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              ),
              true
            )}
            <td
              style={{
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            ></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Q4
            </th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Nov
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 10
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 10 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 10 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  moment(t.entryDate, "DD/MM/YY").month() === 9 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 10 ||
                  moment(t.entryDate, "DD/MM/YY").month() === 11
              )
            )}
          </tr>

          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            ></th>
            <th
              style={{
                fontWeight: "400",
              }}
              scope="row"
            >
              Dec
            </th>

            {runSpeficCal(
              trades.filter(
                (t) => moment(t.entryDate, "DD/MM/YY").month() === 11
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 11 &&
                    t.direction === "Long") ||
                  t.optionType === "Call"
              )
            )}
            {runSpeficCal(
              trades.filter(
                (t) =>
                  (moment(t.entryDate, "DD/MM/YY").month() === 11 &&
                    t.direction === "Short") ||
                  t.optionType === "Put"
              )
            )}
            <td></td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
              scope="row"
            ></th>
            <td
              style={{
                textAlign: "left",
                fontWeight: "400",
                borderTop: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              Total
            </td>
            {runSpeficCal(trades, true)}
            {runSpeficCal(
              trades.filter(
                (t) => t.direction === "Long" || t.optionType === "Call"
              ),
              true
            )}
            {runSpeficCal(
              trades.filter(
                (t) => t.direction === "Short" || t.optionType === "Put"
              ),
              true
            )}
            {runSpeficCal(trades, true)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PercentReturns;
