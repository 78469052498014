import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AuthContext from "../../lib/auth-context";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Yearly = ({ calculateFields, theme, selected }) => {
  const auth = useContext(AuthContext);
  const backTestId = localStorage.getItem("backtest-active");
  const backtestingMode = auth.backtestingMode;
  const [percentReturn, setPercentReturn] = useState([]);
  const [moneyReturn, setMoneyReturn] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const backtestActive = localStorage.getItem("backtest-active");

  useEffect(() => {
    workOutCurvePoints(localStorage.getItem("year"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, mode, backtestActive, localStorage.getItem("year")]);

  const Options = mode === "Options";
  const all = mode === "Personal & Investor Capital";
  const personal = mode === "Personal Capital";
  const investor = mode === "Investor Capital";

  const workOutCurvePoints = async (years) => {
    let trades = await calculateFields(
      auth.trades,
      backtestingMode,
      backtestingMode ? backTestId : "",
      false,
      true
    );
    if (backtestingMode) {
      if (backTestId === "Missed Trades") {
        trades = trades.filter((t) => t.missedTrade === "Yes");
      } else {
        trades = trades.filter((t) => t.testId === backTestId);
      }
    }

    const year_1 = [];
    const year_2 = [];
    const year_3 = [];
    const year_4 = [];
    const year_5 = [];
    const year_6 = [];
    const year_7 = [];
    const year_8 = [];
    const year_9 = [];
    const year_10 = [];

    const years_array = [
      year_1,
      year_2,
      year_3,
      year_4,
      year_5,
      year_6,
      year_7,
      year_8,
      year_9,
      year_10,
    ];

    trades.forEach((t) => {
      const trade_q = moment(t.entryDate, "DD/MM/YYYY").year();
      auth.allYearsOfTrades.forEach((year, index) => {
        if (trade_q.toString() === year) years_array[index].push(t);
      });
    });

    // percentage
    let yearlyResult = [];
    let totalForEachYear = [];
    // money
    let yearlyResultDollar = [];

    const returnArray = (index) => {
      switch (index) {
        case 0:
          return year_1;
        case 1:
          return year_2;
        case 2:
          return year_3;
        case 3:
          return year_4;
        case 4:
          return year_5;
        case 5:
          return year_6;
        case 6:
          return year_7;
        case 7:
          return year_8;
        case 8:
          return year_9;
        case 9:
          return year_10;
        default:
          return [];
      }
    };

    let balanceArray = [];
    let array_of_years_traded = auth.allYearsOfTrades;
    let balance;
    let percent;

    const firstYear = array_of_years_traded[0];
    const user = JSON.parse(localStorage.getItem("user"));

    if (personal) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalancePC);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesPC"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );
      }
    } else if (investor) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalanceIC);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesIC"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );
      }
    } else if (Options) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalanceOptions);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesOptions"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );
      }
    }

    for (let index = 0; index < years_array.length; index++) {
      if (index > 0);

      //------------------------Percent--------------------------//

      percent = returnArray(index).map((trade) =>
        personal
          ? parseFloat(trade.profitLossPercentagePC)
          : investor
          ? parseFloat(trade.profitLossPercentageIC)
          : Options
          ? parseFloat(trade.profitLossPercentageOptions)
          : 0
      );

      percent =
        percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
      totalForEachYear.push(percent);
      yearlyResult.push(totalForEachYear.reduce((acc, curr) => acc + curr));

      //-------------------------Dollar-------------------------//

      if (personal) {
        balance =
          returnArray(index)[returnArray(index).length - 1]?.rollingBalancePC;
      } else if (investor) {
        balance =
          returnArray(index)[returnArray(index).length - 1]?.rollingBalanceIC;
      } else if (Options) {
        balance =
          returnArray(index)[returnArray(index).length - 1]
            ?.rollingBalanceOptions;
      }

      if (balance) {
        balanceArray.push(balance);
      } else if (balance === undefined) {
        balance = balanceArray[balanceArray.length - 1];
      }

      yearlyResultDollar.push(balance);

      //--------------------------------------------------//
    }
    setPercentReturn(yearlyResult);
    setMoneyReturn(yearlyResultDollar);
  };
  const options = {
    responsive: true,
    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

    interaction: {
      mode: "index",
      intersect: false,
    },

    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },

    scales: {
      x: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        display: true,
        position: "bottom",
        grid: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
      y1: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
    },
  };
  const labels = auth.allYearsOfTrades;

  const data = {
    labels,
    datasets: [
      {
        label: personal
          ? "PC Percentage"
          : investor
          ? "IC Percentage"
          : all
          ? "PIC Percentage"
          : "",
        data: percentReturn,
        borderColor: "blue",
        backgroundColor: "blue",
        yAxisID: "y1",
      },
      {
        label: personal
          ? "PC Equity"
          : investor
          ? "IC Equity"
          : all
          ? "PIC Equity"
          : "",
        data: moneyReturn,
        borderColor: "red",
        backgroundColor: "red",
        yAxisID: "y",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Yearly;
