import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AuthContext from "../../lib/auth-context";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Weekly = ({ calculateFields, theme, selected }) => {
  const auth = useContext(AuthContext);
  const backTestId = localStorage.getItem("backtest-active");
  const backtestingMode = auth.backtestingMode;
  const [percentReturn, setPercentReturn] = useState([]);
  const [moneyReturn, setMoneyReturn] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const backtestActive = localStorage.getItem("backtest-active");

  useEffect(() => {
    workOutCurvePoints(localStorage.getItem("year"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, mode, backtestActive, localStorage.getItem("year")]);

  const Options = mode === "Options";
  const personal = mode === "Personal Capital";
  const investor = mode === "Investor Capital";

  const workOutCurvePoints = async (years) => {
    let trades = await calculateFields(
      auth.trades,
      backtestingMode,
      backtestingMode ? backTestId : "",
      false,
      true
    );

    if (backtestingMode) {
      if (backTestId === "Missed Trades") {
        trades = trades.filter((t) => t.missedTrade === "Yes");
      } else {
        trades = trades.filter((t) => t.testId === backTestId);
      }
    }

    if (trades.length > 0);

    trades = trades.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );

    if (years !== "0") {
      trades = trades.filter(
        (t) => moment(t.entryDate, "DD/MM/YYYY").format("Y") === years
      );
    }

    const organised = [];

    trades.forEach((trade) => {
      const week = moment(trade.entryDate, "DD/MM/YYYY").format("W");
      for (let index = 0; index <= 52; index++) {
        if (parseInt(week) === index + 1) {
          organised.push(Object.assign(trade, { week: parseInt(week) }));
        }
      }
    });

    trades = organised;

    const week1 = [];
    const week2 = [];
    const week3 = [];
    const week4 = [];
    const week5 = [];
    const week6 = [];
    const week7 = [];
    const week8 = [];
    const week9 = [];
    const week10 = [];
    const week11 = [];
    const week12 = [];
    const week13 = [];
    const week14 = [];
    const week15 = [];
    const week16 = [];
    const week17 = [];
    const week18 = [];
    const week19 = [];
    const week20 = [];
    const week21 = [];
    const week22 = [];
    const week23 = [];
    const week24 = [];
    const week25 = [];
    const week26 = [];
    const week27 = [];
    const week28 = [];
    const week29 = [];
    const week30 = [];
    const week31 = [];
    const week32 = [];
    const week33 = [];
    const week34 = [];
    const week35 = [];
    const week36 = [];
    const week37 = [];
    const week38 = [];
    const week39 = [];
    const week40 = [];
    const week41 = [];
    const week42 = [];
    const week43 = [];
    const week44 = [];
    const week45 = [];
    const week46 = [];
    const week47 = [];
    const week48 = [];
    const week49 = [];
    const week50 = [];
    const week51 = [];
    const week52 = [];

    trades.forEach((trade) => {
      if (trade.week === 1) {
        week1.push(trade);
      } else if (trade.week === 2) {
        week2.push(trade);
      } else if (trade.week === 3) {
        week3.push(trade);
      } else if (trade.week === 4) {
        week4.push(trade);
      } else if (trade.week === 5) {
        week5.push(trade);
      } else if (trade.week === 6) {
        week6.push(trade);
      } else if (trade.week === 7) {
        week7.push(trade);
      } else if (trade.week === 8) {
        week8.push(trade);
      } else if (trade.week === 9) {
        week9.push(trade);
      } else if (trade.week === 10) {
        week10.push(trade);
      } else if (trade.week === 11) {
        week11.push(trade);
      } else if (trade.week === 12) {
        week12.push(trade);
      } else if (trade.week === 13) {
        week13.push(trade);
      } else if (trade.week === 14) {
        week14.push(trade);
      } else if (trade.week === 15) {
        week15.push(trade);
      } else if (trade.week === 16) {
        week16.push(trade);
      } else if (trade.week === 17) {
        week17.push(trade);
      } else if (trade.week === 18) {
        week18.push(trade);
      } else if (trade.week === 19) {
        week19.push(trade);
      } else if (trade.week === 20) {
        week20.push(trade);
      } else if (trade.week === 21) {
        week21.push(trade);
      } else if (trade.week === 22) {
        week22.push(trade);
      } else if (trade.week === 23) {
        week23.push(trade);
      } else if (trade.week === 24) {
        week24.push(trade);
      } else if (trade.week === 25) {
        week25.push(trade);
      } else if (trade.week === 26) {
        week26.push(trade);
      } else if (trade.week === 27) {
        week27.push(trade);
      } else if (trade.week === 28) {
        week28.push(trade);
      } else if (trade.week === 29) {
        week29.push(trade);
      } else if (trade.week === 30) {
        week30.push(trade);
      } else if (trade.week === 31) {
        week31.push(trade);
      } else if (trade.week === 32) {
        week32.push(trade);
      } else if (trade.week === 33) {
        week33.push(trade);
      } else if (trade.week === 34) {
        week34.push(trade);
      } else if (trade.week === 35) {
        week35.push(trade);
      } else if (trade.week === 36) {
        week36.push(trade);
      } else if (trade.week === 37) {
        week37.push(trade);
      } else if (trade.week === 38) {
        week38.push(trade);
      } else if (trade.week === 39) {
        week39.push(trade);
      } else if (trade.week === 40) {
        week40.push(trade);
      } else if (trade.week === 41) {
        week41.push(trade);
      } else if (trade.week === 42) {
        week42.push(trade);
      } else if (trade.week === 43) {
        week43.push(trade);
      } else if (trade.week === 44) {
        week44.push(trade);
      } else if (trade.week === 45) {
        week45.push(trade);
      } else if (trade.week === 46) {
        week46.push(trade);
      } else if (trade.week === 47) {
        week47.push(trade);
      } else if (trade.week === 48) {
        week48.push(trade);
      } else if (trade.week === 49) {
        week49.push(trade);
      } else if (trade.week === 50) {
        week50.push(trade);
      } else if (trade.week === 51) {
        week51.push(trade);
      } else if (trade.week === 52) {
        week52.push(trade);
      }
    });

    const returnArray = (index) => {
      switch (index) {
        case 1:
          return week1;
        case 2:
          return week2;
        case 3:
          return week3;
        case 4:
          return week4;
        case 5:
          return week5;
        case 6:
          return week6;
        case 7:
          return week7;
        case 8:
          return week8;
        case 9:
          return week9;
        case 10:
          return week10;
        case 11:
          return week11;
        case 12:
          return week12;
        case 13:
          return week13;
        case 14:
          return week14;
        case 15:
          return week15;
        case 16:
          return week16;
        case 17:
          return week17;
        case 18:
          return week18;
        case 19:
          return week19;
        case 20:
          return week20;
        case 21:
          return week21;
        case 22:
          return week22;
        case 23:
          return week23;
        case 24:
          return week24;
        case 25:
          return week25;
        case 26:
          return week26;
        case 27:
          return week27;
        case 28:
          return week28;
        case 29:
          return week29;
        case 30:
          return week30;
        case 31:
          return week31;
        case 32:
          return week32;
        case 33:
          return week33;
        case 34:
          return week34;
        case 35:
          return week35;
        case 36:
          return week36;
        case 37:
          return week37;
        case 38:
          return week38;
        case 39:
          return week39;
        case 40:
          return week40;
        case 41:
          return week41;
        case 42:
          return week42;
        case 43:
          return week43;
        case 44:
          return week44;
        case 45:
          return week45;
        case 46:
          return week46;
        case 47:
          return week47;
        case 48:
          return week48;
        case 49:
          return week49;
        case 50:
          return week50;
        case 51:
          return week51;
        case 52:
          return week52;
        default:
          return [];
      }
    };

    // percentage
    let weeklyResult = [];
    let totalForEachWeek = [];
    // money
    let weeklyResultDollar = [];
    let counter;

    if (Number(years) < moment().year()) {
      counter = 52;
    } else {
      counter =
        moment().month() === 11 && moment().week() === 1 ? 52 : moment().week();
    }

    let balanceArray = [];
    let array_of_years_traded = auth.allYearsOfTrades;
    let balance;
    let percent;

    const firstYear = array_of_years_traded[0];
    const user = JSON.parse(localStorage.getItem("user"));

    if (personal) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalancePC);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesPC"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );

        // weeklyResult.push(
        //   JSON.parse(localStorage.getItem("rollingPercentsPC"))?.find(
        //     (element) =>
        //       element.title.includes(Number(localStorage.getItem("year")) - 1)
        //   )?.value
        // );



      }
    } else if (investor) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalanceIC);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesIC"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );
        // weeklyResult.push(
        //   JSON.parse(localStorage.getItem("rollingPercentsIC"))?.find(
        //     (element) =>
        //       element.title.includes(Number(localStorage.getItem("year")) - 1)
        //   )?.value
        // );
      }
    } else if (Options) {
      if (localStorage.getItem("year") === firstYear) {
        balanceArray.push(user.startingBalanceOptions);
      } else {
        balanceArray.push(
          JSON.parse(localStorage.getItem("rollingBalancesOptions"))?.find(
            (element) =>
              element.title.includes(Number(localStorage.getItem("year")) - 1)
          )?.value
        );

        // weeklyResult.push(
        //   JSON.parse(localStorage.getItem("rollingPercentsOptions"))?.find(
        //     (element) =>
        //       element.title.includes(Number(localStorage.getItem("year")) - 1)
        //   )?.value
        // );
      }
    }

    for (let index = 0; index <= counter; index++) {
      if (index > 0);

      //------------------------Percent--------------------------//

      percent = returnArray(index).map((trade) =>
        personal
          ? parseFloat(trade.profitLossPercentagePC)
          : investor
          ? parseFloat(trade.profitLossPercentageIC)
          : Options
          ? parseFloat(trade.profitLossPercentageOptions)
          : 0
      );

      percent =
        percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
      totalForEachWeek.push(percent);
      weeklyResult.push(totalForEachWeek.reduce((acc, curr) => acc + curr));

      //-------------------------Dollar-------------------------//

      if (personal) {
        balance =
          returnArray(index)[returnArray(index).length - 1]?.rollingBalancePC;
      } else if (investor) {
        balance =
          returnArray(index)[returnArray(index).length - 1]?.rollingBalanceIC;
      } else if (Options) {
        balance =
          returnArray(index)[returnArray(index).length - 1]
            ?.rollingBalanceOptions;
      }

      if (balance) {
        balanceArray.push(balance);
      } else if (balance === undefined) {
        balance = balanceArray[balanceArray.length - 1];
      }

      weeklyResultDollar.push(balance);

      //--------------------------------------------------//
    }
    setPercentReturn(weeklyResult);
    setMoneyReturn(weeklyResultDollar);
  };
  const options = {
    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

    interaction: {
      mode: "index",
      intersect: false,
    },

    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },

    scales: {
      x: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        display: true,
        position: "bottom",
        grid: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
      y1: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
    },
  };
  const labels = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  ];
  const data = {
    labels,
    datasets: [
      {
        label: personal ? "PC Percentage" : investor ? "IC Percentage" : "",
        data: percentReturn,
        borderColor: "blue",
        backgroundColor: "blue",
        yAxisID: "y1",
      },
      {
        label: personal ? "PC Equity" : investor ? "IC Equity" : "",
        data: moneyReturn,
        borderColor: "red",
        backgroundColor: "red",
        yAxisID: "y",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Weekly;
