import useWindowDimensions from "../../utils/width";

const WatchlistWildcardNone = ({ trades, runSpeficCal, theme }) => {
  const win = "#26a65d";
  const loss = "#f23645";
  const breakeven = "#ED9E0E";

  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        padding: "0.7em",
        borderRadius: "10px",

        width: "100%",
        maxWidth: "770px",
      }}
    >
      <table
        className={theme[0] === "dark" ? 'tableHoverEffectDark' : 'tableHoverEffectLight'}
        style={{
          tableLayout: "fixed",
          borderCollapse: "collapse",
          width: "100%",
          height: "200px",
          fontSize: width <= 700 ? "13px" : "18px",
          margin: "0 auto",
        }}
      >
        <caption
          style={{
            marginBottom: "10px",
            fontSize: width <= 700 ? "16px" : "18px",
            fontWeight: "400",
            borderBottom: `1px solid ${
              theme[0] === "dark" ? "#FCFCFF" : "black"
            }`,
            paddingBottom: "10px",
          }}
        >
          Watchlist / Additional / None
        </caption>
        <thead
        className="tableHoverEffectOff"
          style={{ fontSize: width <= 700 ? "13px" : "16px", height: "50px" }}
        >
          <tr>
            <th></th>
            <th style={{ fontWeight: "400", color: win }}>Win</th>
            <th style={{ fontWeight: "400", color: breakeven }}>BE</th>
            <th style={{ fontWeight: "400", color: loss }}>Loss</th>
            <th style={{ fontWeight: "400" }}># Trades</th>
            <th style={{ fontWeight: "400" }}>Return %</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Watchlist
            </th>
            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "Top" &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win" ||
                      t.outcomeOptions === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "Top" &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven" ||
                      t.outcomeOptions === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "Top" &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss" ||
                      t.outcomeOptions === "Loss")
                ).length
              }
            </td>
            <td>{trades.filter((t) => t.highlightedPair === "Top").length}</td>
            {runSpeficCal(trades.filter((t) => t.highlightedPair === "Top"))}
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Additional
            </th>

            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "Additional" &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win" ||
                      t.outcomeOptions === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "Additional" &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven" ||
                      t.outcomeOptions === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "Additional" &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss" ||
                      t.outcomeOptions === "Loss")
                ).length
              }
            </td>

            <td>
              {trades.filter((t) => t.highlightedPair === "Additional").length}
            </td>
            {runSpeficCal(
              trades.filter((t) => t.highlightedPair === "Additional")
            )}
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              None
            </th>

            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "None" &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win" ||
                      t.outcomeOptions === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "None" &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven" ||
                      t.outcomeOptions === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    t.highlightedPair === "None" &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss" ||
                      t.outcomeOptions === "Loss")
                ).length
              }
            </td>

            <td>{trades.filter((t) => t.highlightedPair === "None").length}</td>
            {runSpeficCal(trades.filter((t) => t.highlightedPair === "None"))}
          </tr>

          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Total
            </th>

            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    (t.highlightedPair === "Top" ||
                      t.highlightedPair === "Additional" ||
                      t.highlightedPair === "None") &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win" ||
                      t.outcomeOptions === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    (t.highlightedPair === "Top" ||
                      t.highlightedPair === "Additional" ||
                      t.highlightedPair === "None") &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven" ||
                      t.outcomeOptions === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    (t.highlightedPair === "Top" ||
                      t.highlightedPair === "Additional" ||
                      t.highlightedPair === "None") &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss" ||
                      t.outcomeOptions === "Loss")
                ).length
              }
            </td>
            <td> {trades.filter((t) => t.highlightedPair).length}</td>
            {runSpeficCal(trades)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WatchlistWildcardNone;
